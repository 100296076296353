import 'lazysizes';
import HomepageTemplate from './../templates/HomepageTemplate';
import LazyLoad from 'react-lazyload';
import NoMatch from './NoMatch';
import OwlCarousel from 'react-owl-carousel';
import React, { Component } from 'react';
import ReactGA from 'react-ga';

import {
  getApiEndPoint,
  getBaseUrl,
  getEnv,
  initStickyFooter,
  setupWebsite,
  getCanonical,
} from './Lib';

const $ = window.$;

class Home extends Component {
  constructor(props) {
    super(props);

    setupWebsite(this.props);

    let
      ready = false,
      homepageHtml = {
        __html: '',
      },
      readyTopbanner = false,
      topBannerHtml = {
        __html: '',
      },
      readyBestSeller = false,
      bestSeller = [];

    if (window.homepageHtml) {
      ready = true;
      homepageHtml = window.homepageHtml;
    }

    if (window.bestSeller) {
      readyBestSeller = true;
      bestSeller = window.bestSeller;
    }

    this.state = {
      useHomepageTemplate: false,
      ready: ready,
      homepageHtml: homepageHtml,
      readyTopbanner: readyTopbanner,
      topBannerHtml: topBannerHtml,
      readyBestSeller: readyBestSeller,
      bestSeller: bestSeller,
      bestSellerResponsive: {
        0: {
          items: 1,
        },
        576: {
          items: 2,
        },
        992: {
          items: 3,
        },
        1336: {
          items: 4,
        },
      },
      bestSellerReady: false,
    }
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);

    if (window.errorWebsite) {
      return false;
    }

    //  init Canonical Tag
    getCanonical(this.props);

    if (this.state.useHomepageTemplate || window.homepageHtml) {
      this.initBanner();
    } else {
      this.getHomepage();
      this.getTopBanner();
    }

    if (window.bestSeller) {
      this.initBestSeller();
    } else {
      this.getBestSeller();
    }

    initStickyFooter();
  }

  getHomepage() {
    const homepageIds = {
      au: getEnv('REACT_APP_HOMEPAGE_ID_AU'),
      ca: getEnv('REACT_APP_HOMEPAGE_ID_CA'),
      eu: getEnv('REACT_APP_HOMEPAGE_ID_EU'),
      nz: getEnv('REACT_APP_HOMEPAGE_ID_NZ'),
      uk: getEnv('REACT_APP_HOMEPAGE_ID_UK'),
      us: getEnv('REACT_APP_HOMEPAGE_ID_US'),
    };

    let website = window.website;
    if (website === '') {
      website = 'au';
    }
    if (!homepageIds[website]) {
      alert("Error when get homepage content.");
      return false;
    }

    const url = getApiEndPoint() + 'cmsPage/' + homepageIds[website];

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Authorization": "Bearer " + getEnv('REACT_APP_ACCESS_TOKEN'),
      },
    })
      .then(res => res.json())
      .then(
        (result) => {
          if (result.content) {
            this.setState({
              ready: true,
              homepageHtml: {
                __html: result.content,
              }
            });
          } else {
            alert("Error when get homepage content.");
          }
        },
        (error) => {
          console.log(error);
        }
      )
  }

  getTopBanner() {
    const topBannerIds = {
      au: getEnv('REACT_APP_HOMEPAGE_TOP_BANNER_BLOCK_ID_AU'),
      ca: getEnv('REACT_APP_HOMEPAGE_TOP_BANNER_BLOCK_ID_CA'),
      eu: getEnv('REACT_APP_HOMEPAGE_TOP_BANNER_BLOCK_ID_EU'),
      nz: getEnv('REACT_APP_HOMEPAGE_TOP_BANNER_BLOCK_ID_NZ'),
      uk: getEnv('REACT_APP_HOMEPAGE_TOP_BANNER_BLOCK_ID_UK'),
      us: getEnv('REACT_APP_HOMEPAGE_TOP_BANNER_BLOCK_ID_US'),
    };

    let website = window.website;
    if (website === '') {
      website = 'au';
    }
    if (!topBannerIds[website]) {
      alert("Error when get Top Banner content.");
      return false;
    }

    const url = getApiEndPoint() + 'cmsBlock/' + topBannerIds[website];

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Authorization": "Bearer " + getEnv('REACT_APP_ACCESS_TOKEN'),
      },
    })
    .then(res => res.json())
    .then(
      (result) => {
        if (result.content) {
          
          this.setState({
            readyTopbanner: true,
            topBannerHtml: {
              __html: result.content,
            },
          }, function () {
            this.initBanner();
          });
        } else {
          alert("Error when get Top Banner content.");
        }
      },
      (error) => {
        console.log(error);
      }
    )
  }

  initBanner() {
    $('#top-banner').owlCarousel({
      items: 1,
      loop: true,
      margin: 10,
      nav: true,
      navText: [
        '<img class="ga-icon-related-left" src="' + getEnv('REACT_APP_BASE_URL') + 'static/frontend/TPFGroup/GrownAlchemist/en_AU/images/icon/icon-related-left.svg" />',
        '<img class="ga-icon-related-left" src="' + getEnv('REACT_APP_BASE_URL') + 'static/frontend/TPFGroup/GrownAlchemist/en_AU/images/icon/icon-related-left.svg" />',
      ],
      navElement: 'div',
      dots: false,
      onTranslated: function () {
        // Update Magnify when slide changes
      },
      smartSpeed: 800,
    });
  }

  initBestSeller() {
    const
      bestSeller = this.state.bestSeller;

    bestSeller.forEach(function (product) {
      if (product.type_id === 'configurable') {
        if (product.children.length) {
          product.children[0].active = true;
        }
      }
    });

    this.setState({
      bestSeller: bestSeller,
    });
  }

  getBestSeller() {
    const url = getBaseUrl() + 'grownalchemist/api/homepage';

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Authorization": "Bearer " + getEnv('REACT_APP_ACCESS_TOKEN'),
      },
    })
      .then(res => res.json())
      .then(
        (result) => {
          if (result.bestSeller) {
            const
              bestSeller = result.bestSeller;

            this.setState({
              readyBestSeller: true,
              bestSeller: bestSeller,
            }, function () {
              this.initBestSeller();
            });
          } else {
            alert("Error when get homepage content.");
          }
        },
        (error) => {
          console.log(error);
        }
      )
  }

  addToCart(product) {
    if (this.state.adding) {
      return false;
    }

    const
      that = this,
      url = getBaseUrl() + 'checkout/cart/add',
      data = new FormData();

    // Prepare mini cart data
    let
      miniCart = {},
      activeChild = null;
    miniCart.title = product.name;
    miniCart.size = product.size;
    miniCart.formattedPrice = product.formatted_price;
    miniCart.resizedImage = product.resized_image;

    if (product.type_id === "configurable" && product.children.length) {
      activeChild = product.children.filter(child => {
        return child.active === true
      });
      activeChild = activeChild ? activeChild[0] : null;
    }

    if (activeChild) {
      miniCart.size = activeChild.size;
      miniCart.formattedPrice = activeChild.formatted_price;
      miniCart.resizedImage = activeChild.resized_image;
    }

    data.append('product', product.id);
    data.append('form_key', this.props.inputFormKey);
    data.append('qty', 1);
    if (activeChild) {
      data.append('super_attribute[' + getEnv('REACT_APP_ATTRIBUTE_CS_SIZE_ID') + ']', activeChild.cs_size);
    }

    this.setState({ adding: true });
    setTimeout(function () {
      that.forceUpdate();
    });

    fetch(url, {
      method: 'POST',
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
      body: data,
      credentials: 'include',
    }).then(
      (result) => {
        this.props.getCart(function () {
          that.showMiniCart(miniCart);

          that.setState({ adding: false });
          setTimeout(function () {
            that.forceUpdate();
          });
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }

  showMiniCart(miniCart) {
    this.props.showMiniCart(miniCart);
  }

  changeSwatch(product, csSize) {
    const that = this;
    if (product.type_id === "configurable" && product.children.length) {
      product.children.forEach(function (child) {
        if (child.cs_size === csSize) {
          child.active = true;
        } else {
          child.active = false;
        }
      });
    }
    this.forceUpdate();
    setTimeout(function () {
      that.forceUpdate();
    });
  }

  render() {
    if (window.errorWebsite) {
      return <NoMatch {...this.props} />;
    }

    return (
      <>
        
        {(() => {
          if (this.state.readyTopbanner) {
            return (
              <div dangerouslySetInnerHTML={this.state.topBannerHtml}>
              </div>
            );
          } else {
            return (
              <div className="ga-main loading text-center">
                <i className="fas fa-spinner fa-spin"></i>
              </div>
            );
          }
        })()}

        {this.state.readyBestSeller ? (
          <LazyLoad>
            <div class="ga-best-seller">
              <h2>BEST SELLERS</h2>
            </div>
            <div className="ga-best-seller">
              <OwlCarousel
                className="owl-theme"
                items={3}
                nav
                navText={[
                  '<img class="ga-icon-related-left" src="' + getEnv('REACT_APP_BASE_URL') + 'static/frontend/TPFGroup/GrownAlchemist/en_AU/images/icon/icon-related-left.svg" />',
                  '<img class="ga-icon-related-left" src="' + getEnv('REACT_APP_BASE_URL') + 'static/frontend/TPFGroup/GrownAlchemist/en_AU/images/icon/icon-related-left.svg" />',
                ]}
                navElement='div'
                margin={9}
                dots={0}
                responsive={this.state.bestSellerResponsive}
              >
                {this.state.bestSeller.map((product, i) => {
                  if (product.type_id === "configurable" && !product.children.length) {
                    return false;
                  }

                  let
                    resizedImage = product.resized_image,
                    formattedPrice = product.formatted_price;

                  if (product.type_id === "configurable") {
                    product.children.forEach(function (child) {
                      if (child.active) {
                        resizedImage = child.resized_image;
                        formattedPrice = child.formatted_price;
                      }
                    });
                  }

                  return (
                    <div className="item" key={i}>
                      <div className="hover">
                        <a className="image" href={product.product_url}>
                          <img title={product.name} alt={product.name} src={resizedImage} />
                        </a>
                        <h3>
                          <a href={product.product_url}>
                            {product.name}
                          </a>
                        </h3>
                        {product.cs_ingredient && product.cs_ingredient.trim() ? (
                          <div className="ingredient">
                            {product.cs_ingredient.trim()}
                          </div>
                        ) : ""}
                        <div className="price-box">
                          <span className="price">{formattedPrice}</span>
                        </div>
                        <div className="swatch">
                          {product.type_id === "simple" ? (
                            <span className="swatch-option active">{product.size}</span>
                          ) : ""}
                          {product.type_id === "configurable" && product.children.length ? (
                            <>
                              {product.children.map((child, j) => {
                                return (
                                  <span className={child.active ? "swatch-option active" : "swatch-option"} key={j} onClick={this.changeSwatch.bind(this, product, child.cs_size)}>
                                    {child.size}
                                  </span>
                                );
                              })}
                            </>
                          ) : ""}
                        </div>
                        <div className="buttons">
                          {product.is_saleable ? (
                            <span className="addtocart" onClick={this.addToCart.bind(this, product)}>
                              {this.state.adding ? (
                                <i className="fas fa-spinner fa-spin"></i>
                              ) : "ADD TO BAG"}
                            </span>
                          ) : (
                            <a className="addtocart" href={product.product_url}>OUT OF STOCK</a>
                          )}
                          <a className="product-item-link" href={product.product_url}>VIEW MORE</a>
                        </div>
                      </div>
                      <div className="not-hover">
                        <a className="image" href={product.product_url}>
                          <img title={product.name} alt={product.name} src={product.resized_image} />
                        </a>
                        <h3>
                          <a href={product.product_url}>
                            {product.name}
                          </a>
                        </h3>
                        <div className="price-box">
                          {product.type_id === "configurable" ? "From " : ""}<span className="price">{product.formatted_price}</span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </OwlCarousel>
            </div>
          </LazyLoad>
        ) : (
          <div className="ga-main loading text-center">
            <i className="fas fa-spinner fa-spin"></i>
          </div>
        )}

        {(() => {
          if (this.state.useHomepageTemplate) {
            return (
              <HomepageTemplate />
            );
          } else if (this.state.ready) {
            return (
              <div dangerouslySetInnerHTML={this.state.homepageHtml}>
              </div>
            );
          } else {
            return (
              <div className="ga-main loading text-center">
                <i className="fas fa-spinner fa-spin"></i>
              </div>
            );
          }
        })()}
      </>
    );
  }
}

export default Home;
