import InfiniteScroll from 'react-infinite-scroller';
import NoMatch from './NoMatch';
import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { Link } from "react-router-dom";
import { Row, Col } from 'react-bootstrap';

import {
  setupWebsite,
  initStickyFooter,
  getApiEndPoint,
  isDevelopmentMode,
  getEnv,
  getCanonical,
} from './Lib';

class Blog extends Component {
  constructor(props) {
    super(props);

    setupWebsite(this.props);

    this.state = {
      readyFeatured: false,
      loadingList:   false,
      featuredHtml:  {
        __html: '',
      },
      list:          [],
      currentPage:   0,
      lastPage:      null,
      hasMoreItems:  true,
    }
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);

    if (window.errorWebsite) {
      return false;
    }

    //  init Canonical Tag
    getCanonical(this.props, 4);

    this.getFeatured();

    initStickyFooter();
  }

  getFeatured() {
    const url = getApiEndPoint() + 'cmsBlock/' + getEnv('REACT_APP_BLOG_FEATURED_BLOCK_ID');

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Authorization": "Bearer " + getEnv('REACT_APP_ACCESS_TOKEN'),
      },
    })
      .then(res => res.json())
      .then(
        (result) => {
          if (result.content) {
            this.setState({
              readyFeatured: true,
              featuredHtml:  {
                __html: result.content,
              }
            });
          } else {
            alert("Error when get blog page featured content.");
          }
        },
        (error) => {
          console.log(error);
        }
      )
  }

  getList() {
    if (this.state.loadingList) {
      return false;
    }

    const page = this.state.currentPage + 1;

    if (this.state.lastPage !== null && page > this.state.lastPage) {
      return false;
    }

    const { match: { params } } = this.props;
    const
      storeId = 1,
      limit = 6;
    let
      type = '',
      term = '',
      url = '';

    if (params.id) {
      // Category
      type = 'category';
      term = params.id;
    }

    url = getApiEndPoint() + 'blog/post/list/' + type + '/' + term + '/' + storeId + '/' + page + '/' + limit;

    this.setState({
      loadingList: true,
    }, function() {
      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          "Authorization": "Bearer " + getEnv('REACT_APP_ACCESS_TOKEN'),
        },
      })
        .then(res => res.json())
        .then(
          (result) => {
            try {
              const
                jsonResult   = JSON.parse(result),
                list         = this.state.list,
                lastPage     = jsonResult.last_page,
                hasMoreItems = page >= lastPage ? false : true;

                if (jsonResult.posts && jsonResult.posts.length) {
                  jsonResult.posts.map((post) => {
                    return list.push(post);
                  });
                }

              this.setState({
                currentPage:  page,
                lastPage:     lastPage,
                loadingList:  false,
                list:         list,
                hasMoreItems: hasMoreItems,
              });
            } catch (e) {
              alert("Error when get blog list.");
            }
          },
          (error) => {
            this.setState({
              loadingList:  false,
            });

            console.log(error);
          }
        )
    });
  }

  render() {
  document.title ="Skincare Blog | Healthy Skin Tips | Grown Alchemist"; 
  document.querySelector('meta[name="description"]').content="Get the latest from Grown Alchemist on our skincare blog featuring healthy skin tips, skincare articles, & regular updates about all things skin. Read now!";

    if (window.errorWebsite) {
      return <NoMatch {...this.props} />;
    }

    const loader = (
      <div className="loading text-center" key={0}>
        <i className="fas fa-spinner fa-spin"></i>
      </div>
    );

    return (
      <div className="ga-blog">
        <div className="ga-toolbar">
          <div className="ga-toolbar-top-page-name">
            BLOG
          </div>
        </div>

        {this.state.readyFeatured ? (
          <div className="featured" dangerouslySetInnerHTML={this.state.featuredHtml}>
          </div>
          /**
           * Template
           */
          /*
          <div className="featured">
            <div className="row">
              <div className="col-lg-3">
                <h3>FEATURED</h3>
                <h2>
                  <a href={getBaseUrl()} title="LOREM IPSUM DOLOR SIT AMET, CONSECTETUR ADIPISCING ELIT." className="img-link">
                    LOREM IPSUM DOLOR SIT AMET, CONSECTETUR ADIPISCING ELIT.
                  </a>
                </h2>
                <div className="sum">
                  Vestibulum varius turpis quis diam fermentum, sed porta urna malesuada.
                </div>
                <div className="author">
                  Words by Penny Evangelou<br />
                  Styling by Mia Li
                </div>
                <div className="read-more d-lg-none">
                  <a href={getBaseUrl()} title="READ MORE" className="link">
                    READ MORE
                  </a>
                </div>
              </div>
              <div className="col-lg-9">
                <a href={getBaseUrl()} title="LOREM IPSUM DOLOR SIT AMET, CONSECTETUR ADIPISCING ELIT." className="img-link">
                  <img
                    className="img-fluid"
                    src={getEnv('REACT_APP_MEDIA_URL') + "magefan_blog/blog1.jpg"}
                    alt="Lorem ipsum dolor sit amet, consectetur adipiscing elit." />
                </a>
              </div>
            </div>
          </div>
          */
        ) : (
          <div className="featured loading text-center">
            <i className="fas fa-spinner fa-spin"></i>
          </div>
        )}

        <div className="list">
          <InfiniteScroll
            pageStart={0}
            loadMore={this.getList.bind(this)}
            hasMore={this.state.hasMoreItems}
            loader={loader}>

            <Row>
              {this.state.list.map((post, i) => {
                var src = post.featured_image;
                if (!isDevelopmentMode()) {
                  src = src + "?auto=format,compress&fit=fill&w=1000";
                }

                var link = "/" + window.urlPrefix + "blog/post/" + post.post_id + "/" + post.identifier + ".html";

                if (post.first_category) {
                  var linkCategory = "/" + window.urlPrefix + "blog/category/" + post.first_category.id;
                }
                return (
                  <Col lg={6} key={i}>
                    <Link className="img-link" to={link} title={ post.title }>
                      <img
                        className="img-fluid"
                        src={src}
                        alt={post.title} />
                    </Link>
                    {post.first_category ? (
                      <div className="category">
                        <Link to={linkCategory} title={ post.first_category.title }>
                          { post.first_category.title }
                        </Link>
                      </div>
                    ) : ""}
                    <div className="title">
                      <Link to={link} title={ post.title }>
                        {post.title}
                      </Link>
                    </div>
                    <div className="d-lg-none">
                      <div className="sum" dangerouslySetInnerHTML={{ __html: post.short_content }}></div>
                      <div className="read-more">
                        <Link className="link" to={link} title="READ MORE">
                          READ MORE
                        </Link>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </InfiniteScroll>
        </div>
      </div>
    );
  }
}

export default Blog;
