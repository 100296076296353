import React, { Component } from 'react';
import ReactGA from 'react-ga';

import {
  setupWebsite,
  initStickyFooter,
  getBaseUrl,
} from './Lib';

class NoMatch extends Component {
  constructor(props) {
    super(props);

    setupWebsite(this.props);

    this.goBack = this.goBack.bind(this);
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);

    initStickyFooter();
  }

  goBack() {
    this.props.history.goBack();
  }

  render() {
    return (
      <div className="ga-cms-page ga-404">
        <h1 className="page-title">
          WHOOPS, OUR BAD...
        </h1>
        <div className="content">
          <dl>
            <dt>Sorry, we can’t find that page.</dt>
            <dt>&nbsp;</dt>
            <dt></dt>
            <dt>Why did this happen?</dt>
            <dd>
              <ul className="disc">
                <li>If you typed the URL directly, it may have been entered incorrectly.</li>
                <li>If you clicked on a link to get here, the link is outdated.</li>
              </ul>
            </dd>
          </dl>
          <dl>
            <dt>What can you do?</dt>
            <dd>
              <ul className="disc">
                <li><span className="link" onClick={ this.goBack }>Go back</span> to the previous page or go to <a href={ getBaseUrl() }>Home</a>.</li>
                <li>Use the search bar at the top of the page to search for products.</li>
              </ul>
            </dd>
          </dl>
        </div>
      </div>
    );
  }
}

export default NoMatch;
