import React, { Component } from 'react';

import holidayCollectionBanner1Desktop from './../images/template/holiday-collection-banner1-desktop.jpg';
import holidayCollectionBanner1Mobile from './../images/template/holiday-collection-banner1-mobile.jpg';
import holidayCollectionBanner2Desktop from './../images/template/holiday-collection-banner2-desktop.jpg';
import holidayCollectionBanner2Mobile from './../images/template/holiday-collection-banner2-mobile.jpg';
import holidayCollectionBeautifulBestSellers from './../images/template/holiday-collection-beautiful-bestSellers.jpg';
import holidayCollectionBespokeGiftingWithGrownAlchemistDesktop from './../images/template/holiday-collection-bespoke-gifting-with-grown-alchemist-desktop.jpg';
import holidayCollectionBespokeGiftingWithGrownAlchemistMobile from './../images/template/holiday-collection-bespoke-gifting-with-grown-alchemist-mobile.jpg';
import holidayCollectionDesignedToDelightDesktop from './../images/template/holiday-collection-designed-to-delight-desktop.jpg';
import holidayCollectionDesignedToDelightMobile from './../images/template/holiday-collection-designed-to-delight-mobile.jpg';
import holidayCollectionGiveBeautifullyDesktop from './../images/template/holiday-collection-give-beautifully-desktop.jpg';
import holidayCollectionGiveBeautifullyMobile from './../images/template/holiday-collection-give-beautifully-mobile.jpg';
import holidayCollectionMainDesktop from './../images/template/holiday-collection-main-desktop.jpg';
import holidayCollectionMainMobile from './../images/template/holiday-collection-main-mobile.jpg';
import holidayCollectionPremiumPackaging from './../images/template/holiday-collection-premium-packaging.jpg';

class HolidayCollectionTemplate extends Component {
  render() {
    return (
      <div className="holiday-collection">
        <div className="ga-main ga-main-fluid">
          <div id="top-banner" className="banner">
            <a className="item" href="/">
              <span className="title">
                <span className="h2">
                  INTRODUCING OUR HOLIDAY 2019<br />
                  LIMITED EDITION KITS
                </span>
              </span>
              <img className="d-none d-lg-block img-fluid" src={holidayCollectionMainDesktop} alt="INTRODUCING OUR HOLIDAY 2019 LIMITED EDITION KITS" />
              <img className="d-lg-none img-fluid" src={holidayCollectionMainMobile} alt="INTRODUCING OUR HOLIDAY 2019 LIMITED EDITION KITS" />
            </a>
          </div>
        </div>

        <div className="ga-main">
          <div className="discover">
            <div className="row">
              <div className="col-lg-6 image">
                <a href="/">
                  <img className="d-none d-lg-block img-fluid" src={holidayCollectionGiveBeautifullyDesktop} alt="GIVE BEAUTIFULLY" />
                  <img className="d-lg-none img-fluid" src={holidayCollectionGiveBeautifullyMobile} alt="GIVE BEAUTIFULLY" />
                </a>
              </div>
              <div className="col-lg-6 inf">
                <h2>
                  <a href="/">GIVE BEAUTIFULLY</a>
                </h2>
                <p>Explore our considered curation of aromatic body and hand care, presented in our limited-edition premium white packaging for effortless gifting no matter the recipient.</p>
                <a className="more" href="/">SHOP ALL</a>
              </div>
            </div>
          </div>
        </div>

        <div className="ga-main ga-main-fluid">
          <div id="top-banner" className="banner">
            <a className="item" href="/">
              <span className="title">
                <span className="h2">
                  DESIGNED TO DELIGHT
                </span>
                <span className="p">
                  DISCOVER OUR FULL RANGE OF LIMITED EDITION HOLIDAY KITS BELOW.
                </span>
              </span>
              <img className="d-none d-lg-block img-fluid" src={holidayCollectionDesignedToDelightDesktop} alt="DESIGNED TO DELIGHT" />
              <img className="d-lg-none img-fluid" src={holidayCollectionDesignedToDelightMobile} alt="DESIGNED TO DELIGHT" />
            </a>
          </div>
        </div>

        <div className="ga-main mt-100">
          <div className="shop">
            <div className="row">
              <div className="col-lg-6">
                <a className="img-fluid" href="/">
                  <img className="img-fluid" src={holidayCollectionPremiumPackaging} alt="PREMIUM PACKAGING" />
                </a>

                <div className="row inf">
                  <div className="col">
                    <h2>
                      <a href="/">
                        PREMIUM PACKAGING
                      </a>
                    </h2>
                  </div>
                  <div className="col text-right">
                    <a className="more" href="/">SHOP NOW</a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <a className="img-fluid" href="/">
                  <img className="img-fluid" src={holidayCollectionBeautifulBestSellers} alt="BEAUTIFUL BEST-SELLERS" />
                </a>

                <div className="row inf">
                  <div className="col">
                    <h2>
                      <a href="/">
                        BEAUTIFUL BEST-SELLERS
                      </a>
                    </h2>
                  </div>
                  <div className="col text-right">
                    <a className="more" href="/">SHOP NOW</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="ga-main ga-main-fluid">
          <div id="top-banner" className="banner">
            <a className="item" href="/">
              <img className="d-none d-lg-block img-fluid" src={holidayCollectionBanner1Desktop} alt="BANNER 1" />
              <img className="d-lg-none img-fluid" src={holidayCollectionBanner1Mobile} alt="BANNER 1" />
            </a>
          </div>
        </div>

        <div className="ga-main">
          <div className="discover">
            <div className="row">
              <div className="col-lg-6 image">
                <a href="/">
                  <img className="d-none d-lg-block img-fluid" src={holidayCollectionBespokeGiftingWithGrownAlchemistDesktop} alt="BESPOKE GIFTING WITH GROWN ALCHEMIST" />
                  <img className="d-lg-none img-fluid" src={holidayCollectionBespokeGiftingWithGrownAlchemistMobile} alt="BESPOKE GIFTING WITH GROWN ALCHEMIST" />
                </a>
              </div>
              <div className="col-lg-6 inf">
                <h2 className="font-size-50">
                  <a href="/">BESPOKE GIFTING WITH GROWN ALCHEMIST</a>
                </h2>
                <p>Looking for something outside the box? Generate your own gesture of gratitude this festive season from our range of skin, hair and body care essentials, each hand wrapped in our luxurious brushed-silver envelope and limited-edition foiled holiday sleeve.</p>
                <a className="more" href="/">SHOP CUSTOM GIFTS</a>
              </div>
            </div>
          </div>
        </div>

        <div className="ga-main ga-main-fluid">
          <div className="banner banner-max-1520">
            <a className="item" href="/">
              <img className="d-none d-lg-block img-fluid" src={holidayCollectionBanner2Desktop} alt="BANNER 2" />
              <img className="d-lg-none img-fluid" src={holidayCollectionBanner2Mobile} alt="BANNER 2" />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default HolidayCollectionTemplate;
