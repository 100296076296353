// Packages
import React from 'react';

// Image
import dripTherapyDesktop from './../images/treatment/drip-therapy-desktop.jpg';
import dripTherapyMobile from './../images/treatment/drip-therapy-mobile.jpg';

export default function MakeABookingTemplate() {
  return (
    <>
      <div className="ga-treatment">
        <div className="img">
          <img className="d-none d-lg-block img-fluid" src={dripTherapyDesktop} alt="DRIP THERAPY" />
          <img className="d-lg-none img-fluid" src={dripTherapyMobile} alt="DRIP THERAPY" />
        </div>
        <div className="row content">
          <div className="col-lg-6">
            <h1>DRIP THERAPY</h1>
            <div className="sum">
              <p>VITAMINS AND MINERALS STRAIGHT TO YOUR CELLS</p>
            </div>
          </div>
          <div className="col-lg-6">

            <form className="form" data-react="DripTherapyForm">

              <div className="form-group">
                <p>Drip therapy delivers vitamins and minerals directly to your bloodstream, assisting in optimal nutrient absorption at a cellular level. Our innovative treatments provide immediate benefits at a cellular level, boosting the function of skin cells to achieve your skin goals faster for a more youthful and radiance complexion.</p>

                <p>To receive specific information about our services and intravenous nutrients, we must send it directly via email.</p>
              </div>

              <div className="form-group">
                <label className="label" htmlFor="ga-drip-therapy-firstname"><span>First Name*</span></label>
                <input
                  name="firstname"
                  id="ga-drip-therapy-firstname"
                  type="text"
                  className="input-text"
                />
              </div>

              <div className="form-group">
                <label className="label" htmlFor="ga-drip-therapy-lastname"><span>Last Name*</span></label>
                <input
                  name="lastname"
                  id="ga-drip-therapy-lastname"
                  type="text"
                  className="input-text"
                />
              </div>

              <div className="form-group">
                <label className="label" htmlFor="ga-drip-therapy-telephone"><span>Phone Number*</span></label>
                <input
                  name="telephone"
                  id="ga-drip-therapy-telephone"
                  type="text"
                  className="input-text"
                />
              </div>

              <div className="form-group">
                <label className="label" htmlFor="ga-drip-therapy-email"><span>Email*</span></label>
                <input
                  name="email"
                  id="ga-drip-therapy-email"
                  type="text"
                  className="input-text"
                />
              </div>

              <div className="row">
                <div className="col-sm-8 col-12">
                  <div className="field choice">
                    <label htmlFor="ga-drip-therapy-is-agree" className="label">
                      <span>* I acknowledge that I am requesting medical information as well as general information on Grown Alchemist to be sent to me via email.</span>
                    </label>
                    <input
                      type="checkbox"
                      name="is-agree"
                      id="ga-drip-therapy-is-agree"
                      className="checkbox"
                    />
                  </div>
                </div>
                <div className="col-sm-4 col-12">
                  <div className="field">
                    <button className="button" type="submit">REQUEST</button>
                  </div>
                </div>
              </div>

            </form>

          </div>
        </div>
      </div>
      <div id="popup-success">
        <h2>Thank you</h2>
        <p>Thank you for your interest in our Drip Therapy service. A member of the Grown Alchemist team will be in contact with you shortly.</p>
      </div>
    </>
  );
}