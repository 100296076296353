// Packages
import Modal from "react-responsive-modal";
import React, { Component } from 'react';
import ReactGA from 'react-ga';
import validator from 'validator';

// Page
import NoMatch from './NoMatch';

// Template
import DripTherapyConsentTemplate from './../templates/DripTherapyConsentTemplate';
import DripTherapyTemplate from './../templates/DripTherapyTemplate';
import MakeABookingTemplate from '../templates/MakeABookingTemplate';

// Library
import {
  getApiEndPoint,
  getBaseUrl,
  getEnv,
  setupWebsite,
  getCanonical,
} from './Lib';

// Css
import './PageTreatment.scss';

const $ = window.$;

class Page extends Component {
  constructor(props) {
    super(props);

    setupWebsite(this.props);

    this.state = {
      usePageTemplate: false,
      ready: false,
      pageHtml: '',
      meta_description:'',
      meta_title:'',
      formSubmitButtonText: '',

      popupSuccessOpen: false,
      popupSuccessHtml: '<h2>Success</h2>',
    };

    this.handleOpenSuccess = this.handleOpenSuccess.bind(this);
    this.handleCloseSuccess = this.handleCloseSuccess.bind(this);
  }

  handleOpenSuccess() {
    this.setState({ popupSuccessOpen: true });
  };

  handleCloseSuccess() {
    this.setState({ popupSuccessOpen: false });
  };

  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);

    if (window.errorWebsite) {
      return false;
    }

    //  init Canonical Tag
    getCanonical(this.props, 6);

    if (this.state.usePageTemplate) {
      this.initPage();
    } else {
      this.getPage();
    }
  }

  bookingSubmit() {
    if (this.bookingValidate()) {
      this.bookingCallSubmit();
    }
  }

  dripTherapySubmit() {
    if (this.dripTherapyValidate()) {
      this.dripTherapyCallSubmit();
    }
  }

  bookingValidate() {
    const
      bookingForm = $("#cms-page .form[data-react='BookingForm']");

    let
      bookingHasErrorField = false,

      bookingFirstnameError = false,
      bookingLastnameError = false,
      bookingTelephoneError = false,
      bookingEmailError = false,
      bookingSkinError = false,
      bookingTreatmentError = false,
      bookingBoosterError = false,
      bookingTreatmentDateError = false,
      bookingTreatmentTimeError = false,
      bookingMedicalError = false,
      bookingIsAgreeError = false;

    if (!bookingForm.find("#ga-booking-firstname").val()) {
      bookingHasErrorField = true;
      bookingFirstnameError = true;
    }

    if (!bookingForm.find("#ga-booking-lastname").val()) {
      bookingHasErrorField = true;
      bookingLastnameError = true;
    }

    if (!bookingForm.find("#ga-booking-telephone").val()) {
      bookingHasErrorField = true;
      bookingTelephoneError = true;
    }

    if (!bookingForm.find("#ga-booking-email").val()) {
      bookingHasErrorField = true;
      bookingEmailError = 'Please enter email';
    } else if (!validator.isEmail(bookingForm.find("#ga-booking-email").val())) {
      bookingHasErrorField = true;
      bookingEmailError = 'Please enter a valid email address (Ex: johndoe@domain.com).';
    }

    const
      skins = bookingForm.find("input[type='checkbox'][name='skin']:checked");
    if (!skins.length) {
      bookingHasErrorField = true;
      bookingSkinError = true;
    }

    const
      treatments = bookingForm.find("input[type='checkbox'][name='treatment']:checked");
    if (!treatments.length) {
      bookingHasErrorField = true;
      bookingTreatmentError = true;
    }

    const
      boosters = bookingForm.find("input[type='checkbox'][name='booster']:checked");
    if (!boosters.length) {
      bookingHasErrorField = true;
      bookingBoosterError = true;
    }

    if (!bookingForm.find("#ga-booking-treatment-date").val()) {
      bookingHasErrorField = true;
      bookingTreatmentDateError = true;
    }

    const
      treatmentTimes = bookingForm.find("input[type='checkbox'][name='treatment_time']:checked");
    if (!treatmentTimes.length) {
      bookingHasErrorField = true;
      bookingTreatmentTimeError = true;
    }

    const
      medicals = bookingForm.find("input[type='checkbox'][name='medical']:checked");
    if (!medicals.length) {
      bookingHasErrorField = true;
      bookingMedicalError = true;
    }

    if (!bookingForm.find("#ga-is-agree:checked").length) {
      bookingHasErrorField = true;
      bookingIsAgreeError = true;
    }

    this.setState({
      bookingHasErrorField: bookingHasErrorField,

      bookingFirstnameError: bookingFirstnameError,
      bookingLastnameError: bookingLastnameError,
      bookingTelephoneError: bookingTelephoneError,
      bookingEmailError: bookingEmailError,
      bookingSkinError: bookingSkinError,
      bookingTreatmentError: bookingTreatmentError,
      bookingBoosterError: bookingBoosterError,
      bookingTreatmentDateError: bookingTreatmentDateError,
      bookingTreatmentTimeError: bookingTreatmentTimeError,
      bookingMedicalError: bookingMedicalError,
      bookingIsAgreeError: bookingIsAgreeError,
    }, () => {
      this.bookingFocus();
    });

    return !bookingHasErrorField;
  }

  dripTherapyValidate() {
    const
      dripTherapyForm = $("#cms-page .form[data-react='DripTherapyForm']");

    let
      dripTherapyHasErrorField = false,

      dripTherapyFirstnameError = false,
      dripTherapyLastnameError = false,
      dripTherapyTelephoneError = false,
      dripTherapyEmailError = false,
      dripTherapyIsAgreeError = false;

    if (!dripTherapyForm.find("#ga-drip-therapy-firstname").val()) {
      dripTherapyHasErrorField = true;
      dripTherapyFirstnameError = true;
    }

    if (!dripTherapyForm.find("#ga-drip-therapy-lastname").val()) {
      dripTherapyHasErrorField = true;
      dripTherapyLastnameError = true;
    }

    if (!dripTherapyForm.find("#ga-drip-therapy-telephone").val()) {
      dripTherapyHasErrorField = true;
      dripTherapyTelephoneError = true;
    }

    if (!dripTherapyForm.find("#ga-drip-therapy-email").val()) {
      dripTherapyHasErrorField = true;
      dripTherapyEmailError = 'Please enter email';
    } else if (!validator.isEmail(dripTherapyForm.find("#ga-drip-therapy-email").val())) {
      dripTherapyHasErrorField = true;
      dripTherapyEmailError = 'Please enter a valid email address (Ex: johndoe@domain.com).';
    }

    if (!dripTherapyForm.find("#ga-drip-therapy-is-agree:checked").length) {
      dripTherapyHasErrorField = true;
      dripTherapyIsAgreeError = true;
    }

    this.setState({
      dripTherapyHasErrorField: dripTherapyHasErrorField,

      dripTherapyFirstnameError: dripTherapyFirstnameError,
      dripTherapyLastnameError: dripTherapyLastnameError,
      dripTherapyTelephoneError: dripTherapyTelephoneError,
      dripTherapyEmailError: dripTherapyEmailError,
      dripTherapyIsAgreeError: dripTherapyIsAgreeError,
    }, () => {
      this.dripTherapyFocus();
    });

    return !dripTherapyHasErrorField;
  }

  bookingFocus() {
    const
      bookingForm = $("#cms-page .form[data-react='BookingForm']");

    if (this.state.bookingFirstnameError) {
      alert("Please enter first name");
      bookingForm.find("#ga-booking-firstname").focus();
    } else if (this.state.bookingLastnameError) {
      alert("Please enter last name");
      bookingForm.find("#ga-booking-lastname").focus();
    } else if (this.state.bookingTelephoneError) {
      alert("Please enter telephone");
      bookingForm.find("#ga-booking-telephone").focus();
    } else if (this.state.bookingEmailError) {
      alert(this.state.bookingEmailError);
      bookingForm.find("#ga-booking-email").focus();
    } else if (this.state.bookingSkinError) {
      alert("Please chose your skin");
    } else if (this.state.bookingTreatmentError) {
      alert("Please chose treatment");
    } else if (this.state.bookingBoosterError) {
      alert("Please chose treatment booster");
    } else if (this.state.bookingTreatmentDateError) {
      alert("Please enter treatment date");
      bookingForm.find("#ga-booking-treatment-date").focus();
    } else if (this.state.bookingTreatmentTimeError) {
      alert("Please chose treatment time");
    } else if (this.state.bookingMedicalError) {
      alert("Please chose medical conditions");
    } else if (this.state.bookingIsAgreeError) {
      alert("Please agree");
    }
  }

  dripTherapyFocus() {
    const
      dripTherapyForm = $("#cms-page .form[data-react='DripTherapyForm']");

    if (this.state.dripTherapyFirstnameError) {
      alert("Please enter first name");
      dripTherapyForm.find("#ga-drip-therapy-firstname").focus();
    } else if (this.state.dripTherapyLastnameError) {
      alert("Please enter last name");
      dripTherapyForm.find("#ga-drip-therapy-lastname").focus();
    } else if (this.state.dripTherapyTelephoneError) {
      alert("Please enter telephone");
      dripTherapyForm.find("#ga-drip-therapy-telephone").focus();
    } else if (this.state.dripTherapyEmailError) {
      alert(this.state.dripTherapyEmailError);
      dripTherapyForm.find("#ga-drip-therapy-email").focus();
    } else if (this.state.dripTherapyIsAgreeError) {
      alert("Please agree");
    }
  }

  bookingCallSubmit() {
    if (this.state.submiting) {
      return false;
    }

    const
      bookingForm = $("#cms-page .form[data-react='BookingForm']"),
      url = getBaseUrl() + 'grownalchemist/api/submitTreatment',
      email = bookingForm.find("#ga-booking-email").val(),
      firstname = bookingForm.find("#ga-booking-firstname").val(),
      lastname = bookingForm.find("#ga-booking-lastname").val(),
      telephone = bookingForm.find("#ga-booking-telephone").val(),
      form_key = this.props.inputFormKey,
      skins = bookingForm.find("input[type='checkbox'][name='skin']:checked"),
      treatments = bookingForm.find("input[type='checkbox'][name='treatment']:checked"),
      boosters = bookingForm.find("input[type='checkbox'][name='booster']:checked"),
      treatment_date = bookingForm.find("#ga-booking-treatment-date").val(),
      treatment_time = bookingForm.find("input[type='checkbox'][name='treatment_time']:checked").first().val(),
      medical = bookingForm.find("input[type='checkbox'][name='medical']:checked").first().val(),
      medical_comment = bookingForm.find("#ga-booking-medical-comment").val();

    let
      skinArray = [],
      treatmentArray = [],
      boosterArray = [];

    if (skins.length) {
      skins.each((index, skin) => {
        skinArray.push($(skin).val());
      })
    }

    if (treatments.length) {
      treatments.each((index, treatment) => {
        treatmentArray.push($(treatment).val());
      })
    }

    if (boosters.length) {
      boosters.each((index, booster) => {
        boosterArray.push($(booster).val());
      })
    }

    bookingForm.find("button[type='submit']").html('<i class="fas fa-spinner fa-spin"></i>');
    this.disableFormInput(bookingForm);
    this.setState({ submiting: true }, function () {
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: email,
          firstname: firstname,
          lastname: lastname,
          telephone: telephone,
          form_key: form_key,
          skin: skinArray,
          treatment: treatmentArray,
          booster: boosterArray,
          treatment_date: treatment_date,
          treatment_time: treatment_time,
          medical: medical,
          medical_comment: medical_comment,
        }),
        credentials: 'include',
      })
        .then(res => res.json())
        .then(
          (result) => {
            bookingForm.find("button[type='submit']").html(this.state.formSubmitButtonText);
            this.enableFormInput(bookingForm);
            this.setState({ submiting: false });

            if (result.success) {
              const
                successRedirect = $("#cms-page #success-redirect");

              if (successRedirect.length) {
                window.location = successRedirect.text();
              } else {
                this.handleOpenSuccess();
                this.bookingResetForm();
              }
            } else if (result.message) {
              alert(result.message);
            }
          },
          (error) => {
            bookingForm.find("button[type='submit']").html(this.state.formSubmitButtonText);
            this.enableFormInput(bookingForm);
            this.setState({ submiting: false });

            alert(error);
          }
        );
    });
  }

  dripTherapyCallSubmit() {
    if (this.state.submitingDripTherapy) {
      return false;
    }

    const
      dripTherapyForm = $("#cms-page .form[data-react='DripTherapyForm']"),
      url = getBaseUrl() + 'grownalchemist/api/submitSubscriberTherapy',
      email = dripTherapyForm.find("#ga-drip-therapy-email").val(),
      firstname = dripTherapyForm.find("#ga-drip-therapy-firstname").val(),
      lastname = dripTherapyForm.find("#ga-drip-therapy-lastname").val(),
      telephone = dripTherapyForm.find("#ga-drip-therapy-telephone").val(),
      form_key = this.props.inputFormKey;

    dripTherapyForm.find("button[type='submit']").html('<i class="fas fa-spinner fa-spin"></i>');
    this.disableFormInput(dripTherapyForm);
    this.setState({ submitingDripTherapy: true }, function () {
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: email,
          firstname: firstname,
          lastname: lastname,
          telephone: telephone,
          form_key: form_key,
        }),
        credentials: 'include',
      })
        .then(res => res.json())
        .then(
          (result) => {
            dripTherapyForm.find("button[type='submit']").html(this.state.formSubmitButtonText);
            this.enableFormInput(dripTherapyForm);
            this.setState({ submitingDripTherapy: false });

            if (result.success) {
              this.handleOpenSuccess();
              this.dripTherapyResetForm();
            } else if (result.message) {
              alert(result.message);
            }
          },
          (error) => {
            dripTherapyForm.find("button[type='submit']").html(this.state.formSubmitButtonText);
            this.enableFormInput(dripTherapyForm);
            this.setState({ submitingDripTherapy: false });

            alert(error);
          }
        );
    });
  }

  bookingResetForm() {
    const
      bookingForm = $("#cms-page .form[data-react='BookingForm']");

    bookingForm.find("input[type='text'], input[type='email'], textarea").each((index, obj) => {
      $(obj).val('');
    });

    bookingForm.find("input[type='checkbox']").each((index, obj) => {
      $(obj).prop('checked', false).trigger('change');
    });
  }

  dripTherapyResetForm() {
    const
      dripTherapyForm = $("#cms-page .form[data-react='DripTherapyForm']");

    dripTherapyForm.find("input[type='text'], input[type='email'], textarea").each((index, obj) => {
      $(obj).val('');
    });

    dripTherapyForm.find("input[type='checkbox']").each((index, obj) => {
      $(obj).prop('checked', false).trigger('change');
    });
  }

  unCheckAllCheckbox(obj, currentObj) {
    obj.find("input[type='checkbox']").not(currentObj).each((index, o) => {
      $(o).parents(".label").removeClass("checked");
      $(o).prop('checked', false);
    });
  }

  initFormInput(form) {
    form.find(".field.choice input[type='checkbox']").change(e => {
      const
        $this = $(e.currentTarget);

      if ($this.parents(".radio").length) {
        this.unCheckAllCheckbox($this.parents(".radio"), $this);
      }

      if ($this.is(":checked")) {
        $this.parents(".field.choice").find(".label").addClass("checked");
      } else {
        $this.parents(".field.choice").find(".label").removeClass("checked");
      }
    });

    // formSubmitButtonText
    const
      formSubmitButtonText = form.find("button[type='submit']").text();

    this.setState({
      formSubmitButtonText: formSubmitButtonText,
    });
  }

  disableFormInput(form) {
    form.find("input, textarea, button").prop("disabled", true);
  }

  enableFormInput(form) {
    form.find("input, textarea, button").prop("disabled", false);
  }

  initPopupSuccess() {
    const
      popupSuccess = $("#cms-page #popup-success");

    if (popupSuccess.length) {
      this.setState({
        popupSuccessHtml: popupSuccess.html(),
      });
    }
  }

  initPage() {
    const
      bookingForm = $("#cms-page .form[data-react='BookingForm']"),
      dripTherapyForm = $("#cms-page .form[data-react='DripTherapyForm']");

    if (bookingForm.length) {
      this.initFormInput(bookingForm);

      bookingForm.submit((e) => {
        e.preventDefault();

        this.bookingSubmit();
      });

      this.initPopupSuccess();
    }

    if (dripTherapyForm.length) {
      this.initFormInput(dripTherapyForm);

      dripTherapyForm.submit((e) => {
        e.preventDefault();

        this.dripTherapySubmit();
      });

      this.initPopupSuccess();
    }
  }

  getCmsPage(id) {
    const
      url = getApiEndPoint() + 'cmsPage/' + id;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Authorization": "Bearer " + getEnv('REACT_APP_ACCESS_TOKEN'),
      },
    })
      .then(res => res.json())
      .then(
        (result) => {
          if (result.content) {
            this.setState({
              ready: true,
              pageHtml: result.content,
              meta_description: result.meta_description,
              meta_title:result.meta_title
            }, () => {
              this.initPage();
            });
          } else {
            alert("Error when get page content.");
          }
        },
        (error) => {
          console.log(error);
        }
      )
  }

  getPage() {
    const
      { match: { params } } = this.props,
      url = getBaseUrl() + 'grownalchemist/api/UrlAnalysis';

    fetch(url, {
      method: 'POST',
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
      body: JSON.stringify({
        urls: [
          getBaseUrl() + params.slug,
        ],
      }),
      credentials: 'include',
    })
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result);
          if (result.success && result.data && result.data[0]) {
            const
              data = result.data[0];

            if (data.type === 'cms-page' && data.entityId) {
              const
                id = data.entityId;

              this.getCmsPage(id);
            }
          } else {
            alert("Unknown error!");
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  render() {
    document.title =this.state.meta_title; 
    document.querySelector('meta[name="description"]').content=this.state.meta_description;
    if (window.errorWebsite) {
      return <NoMatch {...this.props} />;
    }

    return (
      <>
        {!this.state.usePageTemplate && !this.state.ready &&
          <div className="ga-main loading text-center">
            <i className="fas fa-spinner fa-spin"></i>
          </div>
        }

        {!this.state.usePageTemplate && this.state.ready &&
          <div id="cms-page" dangerouslySetInnerHTML={{ __html: this.state.pageHtml }}>
          </div>
          // <div>test</div>
        }

        {this.state.usePageTemplate &&
          <div id="cms-page">
            <DripTherapyConsentTemplate />
            <MakeABookingTemplate />
            <DripTherapyTemplate />
          </div>
        }

        <Modal open={this.state.popupSuccessOpen} onClose={this.handleCloseSuccess} classNames={{ modal: 'ga-popup ga-popup-medium pb-14i', closeButton: 'ga-popup-close' }} center>
          <div dangerouslySetInnerHTML={{ __html: this.state.popupSuccessHtml }}>
          </div>
        </Modal>
      </>
    );
  }
}

export default Page;
