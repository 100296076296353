import React, { Component } from 'react';

import newHomepageMadeForMumDesktop from './../images/template/new-homepage-made-for-mum-desktop.jpg';
import newHomepageMadeForMumMobile from './../images/template/new-homepage-made-for-mum-mobile.jpg';
import newHomepageDiscoverDesktop from './../images/template/new-homepage-discover-desktop.jpg';
import newHomepageDiscoverMobile from './../images/template/new-homepage-discover-mobile.jpg';
import newHomepageDiscover2Desktop from './../images/template/new-homepage-discover2-desktop.jpg';
import newHomepageDiscover2Mobile from './../images/template/new-homepage-discover2-mobile.jpg';
import newHomepageShop from './../images/template/new-homepage-shop.jpg';
import newHomepageShop2 from './../images/template/new-homepage-shop2.jpg';
import newHomepageARewardingExperienceDesktop from './../images/template/new-homepage-a-rewarding-experience-desktop.jpg';
import newHomepageARewardingExperienceMobile from './../images/template/new-homepage-a-rewarding-experience-mobile.jpg';

class HomepageTemplate extends Component {
  render() {
    return (
      <>
        <div className="ga-main ga-main-fluid">
          <div id="top-banner" className="banner owl-carousel owl-theme">
            <a className="item" href="/">
              <span className="title">
                <span className="h2">
                  MADE FOR MUM
                </span>
                <span className="p">
                  LIMITED  EDITION MOTHER'S DAY HAND CREAM TRIO.
                </span>
              </span>
              <span className="caption">
                <span className="p">SHOP NOW</span>
              </span>
              <img className="d-none d-lg-block img-fluid" src={ newHomepageMadeForMumDesktop } alt="Grown Alchemist NEW INSTANT SMOOTHING SERUM" />
              <img className="d-lg-none img-fluid" src={ newHomepageMadeForMumMobile } alt="Grown Alchemist NEW INSTANT SMOOTHING SERUM" />
            </a>
            <a className="item" href="/">
              <span className="title">
                <span className="h2">
                  MADE FOR MUM
                </span>
                <span className="p">
                  LIMITED  EDITION MOTHER'S DAY HAND CREAM TRIO.
                </span>
              </span>
              <span className="caption">
                <span className="p">SHOP NOW</span>
              </span>
              <img className="d-none d-lg-block img-fluid" src={ newHomepageMadeForMumDesktop } alt="Grown Alchemist NEW INSTANT SMOOTHING SERUM" />
              <img className="d-lg-none img-fluid" src={ newHomepageMadeForMumMobile } alt="Grown Alchemist NEW INSTANT SMOOTHING SERUM" />
            </a>
          </div>
        </div>

        <div className="ga-main">
          <div className="discover">
            <div className="row">
              <div className="col-lg-6 image">
                <a href="/">
                  <img className="d-none d-lg-block img-fluid" src={ newHomepageDiscoverDesktop } alt="NUTRICOSMETICS SUPPLEMENTS" />
                  <img className="d-lg-none img-fluid" src={ newHomepageDiscoverMobile } alt="NUTRICOSMETICS SUPPLEMENTS" />
                </a>
              </div>
              <div className="col-lg-6 inf">
                <h2>
                  <a href="/">NUTRICOSMETICS SUPPLEMENTS</a>
                  </h2>
                <p>Solenit dolupta tempor aute latium quiaspi squodit hilicae minum qui numentem evel ex ea simintem aut eossequam velit, to blandis et faceatus min pore sum et odi sequatet.</p>
                <a className="more" href="/">DISCOVER MORE</a>
              </div>
            </div>
          </div>

          <div className="discover2">
            <a href="/">
              <img className="d-none d-lg-block img-fluid" src={ newHomepageDiscover2Desktop } alt="DETOX HYDRA-MIST+" />
              <img className="d-lg-none img-fluid" src={ newHomepageDiscover2Mobile } alt="DETOX HYDRA-MIST+" />
            </a>
            {/* <div className="embed-container">
              <iframe title="vimeo-player" src="https://player.vimeo.com/video/336280761" width="640" height="360" frameborder="0" allowfullscreen></iframe>
            </div> */}
            <div className="row">
              <div className="col">
                <span>NEW</span>
                <h2>
                  <a href="/">
                    DETOX HYDRA-MIST+
                  </a>
                </h2>
              </div>
              <div className="col text-right">
                <a className="more" href="/">DISCOVER MORE</a>
              </div>
            </div>
          </div>

          <div className="shop">
            <div className="row">
              <div className="col-lg-6">
                <a className="img-fluid" href="/">
                  <img className="img-fluid" src={ newHomepageShop } alt="PURE ROSEHIP OIL" />
                </a>

                <div className="row inf">
                  <div className="col">
                    <h2>
                      <a href="/">
                        PURE ROSEHIP OIL
                      </a>
                    </h2>
                  </div>
                  <div className="col text-right">
                    <a className="more" href="/">SHOP</a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <a className="img-fluid" href="/">
                  <img className="img-fluid" src={ newHomepageShop2 } alt="DETOX SERUM" />
                </a>

                <div className="row inf">
                  <div className="col">
                    <h2>
                      <a href="/">
                        DETOX SERUM
                      </a>
                    </h2>
                  </div>
                  <div className="col text-right">
                    <a className="more" href="/">SHOP</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="ga-main ga-main-fluid">
          <div className="banner banner-max-1400 banner-white">
            <a className="item" href="/">
              <span className="title small-max">
                <span className="h2">
                  A REWARDING EXPERIENCE
                </span>
                <span className="p">
                  RECEIVE YOUR COMPLIMENTARY LUXURY SAMPLES ON ORDERS OVER $100.
                </span>
              </span>
              <span className="caption">
                <span className="p">SHOP NOW</span>
              </span>
              <img className="d-none d-lg-block img-fluid" src={ newHomepageARewardingExperienceDesktop } alt="A REWARDING EXPERIENCE" />
              <img className="d-lg-none img-fluid" src={ newHomepageARewardingExperienceMobile } alt="A REWARDING EXPERIENCE" />
            </a>
          </div>
        </div>
      </>
    );
  }
}

export default HomepageTemplate;
