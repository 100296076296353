import { Row, Col } from 'react-bootstrap';
import Button from 'react-validation/build/button';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Modal from "react-responsive-modal";
import NoMatch from './NoMatch';
import React, { Component } from 'react';
import ReactGA from 'react-ga';
import validator from 'validator';

import './ProductOrderOfUse.css';

import {
  getApiEndPoint,
  getBaseUrl,
  getEnv,
  initStickyFooter,
  setupWebsite,
  getCanonical,
} from './Lib';

const $ = window.$;

const required = (value, e) => {
  if (!value.toString().trim().length) {
    return <div className="mage-error" generated="true">This is a required field.</div>
  }
};

const email = (value) => {
  if (!validator.isEmail(value)) {
    return <div className="mage-error" generated="true">Please enter a valid email address (Ex: johndoe@domain.com).</div>
  }
};

class ProductOrderOfUse extends Component {
  constructor(props) {
    super(props);

    setupWebsite(this.props);

    this.state = {
      readyProductOrderOfUse: false,
      productOrderOfUse: [],
      readyContent: false,
      initedCheckbox: false,
      sendingEmail: false,
      popupSendMailOpen: false,
      popupSendMailWarningOpen: false,
      popupSendMailSuccessOpen: false,
      popupSaveMyRoutineSuccessOpen: false,
    }

    this.choseProduct = this.choseProduct.bind(this);
    this.sendMail = this.sendMail.bind(this);
    this.handleOpenSendMail = this.handleOpenSendMail.bind(this);
    this.handleCloseSendMail = this.handleCloseSendMail.bind(this);
    this.handleCloseSendMailWarning = this.handleCloseSendMailWarning.bind(this);
    this.handleCloseSendMailSuccess = this.handleCloseSendMailSuccess.bind(this);
    this.handleCloseSaveMyRoutineSuccess = this.handleCloseSaveMyRoutineSuccess.bind(this);
    this.submitSendEmailPopup = this.submitSendEmailPopup.bind(this);
    this.saveMyRoutine = this.saveMyRoutine.bind(this);
  }

  submitSendEmailPopup(e) {
    e.preventDefault();

    this.refFormSendEmailPopup.validateAll();

    if (this.refCheckButtonSendEmailPopup.context._errors.length === 0) {
      this.sendMail();
    }
  }

  isPrint() {
    return this.props.print;
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);

    if (window.errorWebsite) {
      return false;
    }

    //  init Canonical Tag
    getCanonical(this.props, 3);

    this.getProductOrderOfUse();
    this.getContent();

    if (this.isPrint()) {
      document.body.classList.add('print');
    }

    initStickyFooter();
  }

  componentWillUnmount() {
    document.body.classList.remove('print');
  }

  componentDidUpdate() {
    if (this.state.readyProductOrderOfUse && this.state.readyContent && !this.state.initedCheckbox) {
      this.initCheckbox();
      this.initSidebars();
    }
  }

  getProductOrderOfUse() {
    const
      url = getBaseUrl() + 'grownalchemist/api/productOrderOfUse';

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
    })
      .then(res => res.json())
      .then(
        (result) => {
          if (result) {
            this.setState({
              readyProductOrderOfUse: true,
              productOrderOfUse: result,
            });
          } else {
            alert("Error when get product order of use content.");
          }
        },
        (error) => {
          console.log(error);
        }
      )
  }

  getContent() {
    const url = getApiEndPoint() + 'cmsBlock/' + getEnv('REACT_APP_PRODUCT_ORDER_OF_USE_ID');

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Authorization": "Bearer " + getEnv('REACT_APP_ACCESS_TOKEN'),
      },
    })
      .then(res => res.json())
      .then(
        (result) => {
          if (result.content) {
            var object = $($.parseHTML(result.content));

            this.setState({
              readyContent: true,
              banner: {
                __html: object.find('#banner').html(),
              },
              header: {
                __html: object.find('#header').html(),
              },
              grid: {
                __html: object.find('#grid').html(),
              },
            });
          } else {
            alert("Error when get content.");
          }
        },
        (error) => {
          console.log(error);
        }
      )
  }

  initCheckbox() {
    if ($('.ga-product-order-of-use .sidebars .grid').length) {
      $('.ga-product-order-of-use .sidebars .grid .checkbox').click(e => {
        const
          $this = $(e.target),
          sku = $this.data('sku');

        if (sku) {
          ['am', 'pm'].forEach(period => {
            this.choseProductUpdate(period, sku, e.target.checked);
          });
        }
      });
    }

    this.setState({
      initedCheckbox: true,
    }, () => {
      this.setUpDefaultChecked();
      this.setUpDefaultConfigurableProduct();
    });
  }

  initSidebars() {
    if ($('.ga-product-order-of-use .sidebars .grid').length) {
      $('.ga-product-order-of-use .sidebars .grid .section').addClass('active');

      $('.ga-product-order-of-use .sidebars .grid .col .title').click(e => {
        const
          $this = $(e.target);

        $this.parents('.section').toggleClass('active');
      });
    }
  }

  setUpDefaultChecked() {
    const
      slugs = this.props.match.params.slugs;

    if (slugs) {
      slugs.split(",").forEach(sku => {
        if (sku) {
          $(`#${sku}`).click();
        }
      });
    }
  }

  setUpDefaultConfigurableProduct() {
    const
      productOrderOfUse = this.state.productOrderOfUse;

    ['am', 'pm'].forEach(period => {
      productOrderOfUse[period].forEach(sections => {
        const
          products = sections.products;

        products.forEach(product => {
          if (product.type_id === 'configurable') {
            if (product.children.length) {
              product.children[0].active = true;
            }
          }
        });
      })
    });
  }

  choseProductUpdate(period, sku, checked) {
    const
      productOrderOfUse = this.state.productOrderOfUse;

    if (period
      && sku
      && productOrderOfUse
      && productOrderOfUse[period]) {
      let
        product;

      productOrderOfUse[period].forEach(category => {
        if (category.products && !product) {
          product = category.products.find(o => o.sku === sku);
        }
      });

      if (product) {
        product.checked = checked;

        this.setState({
          productOrderOfUse: productOrderOfUse,
        });
      }
    }
  }

  choseProduct(event) {
    const
      period = event.target.dataset.period,
      sku = event.target.dataset.sku;

    this.choseProductUpdate(period, sku, event.target.checked);
  }


  sendMail() {
    const
      values = this.refFormSendEmailPopup.getValues(),
      email = values ? values.email : null,
      currentSlug = this.getCurrentSlug(),
      url = getBaseUrl() + 'grownalchemist/productOrderOfUse/sendMail';

    if (!email) {
      alert('Please enter email');
    }

    if (!this.state.sendingEmail) {
      this.setState({
        sendingEmail: true,
      }, function () {
        fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
          },
          body: JSON.stringify({ email: email, slugs: currentSlug }),
          credentials: 'include',
        })
          .then(res => res.json())
          .then(
            (result) => {
              this.setState({ sendingEmail: false, });

              if (result && result.success) {
                this.setState({
                  popupSendMailOpen: false,
                  popupSendMailSuccessOpen: true,
                });
              } else {
                alert("Error when send mail.");
              }
            },
            (error) => {
              this.setState({ sendingEmail: false, });

              console.log(error);
            }
          );
      });
    }
  }

  handleOpenSendMail() {
    const
      mainList = this.getMainList();

    if (mainList.length) {
      this.setState({ popupSendMailOpen: true });
    } else {
      this.setState({ popupSendMailWarningOpen: true });
    }
  };

  saveMyRoutine() {
    const
      currentSlugNew = this.getCurrentSlugNew(),
      url = getBaseUrl() + 'grownalchemist/productOrderOfUse/save';

    if (!currentSlugNew.length) {
      this.setState({ popupSendMailWarningOpen: true });
      return false;
    }

    if (!this.props.isCustomerLoggedIn) {
      return window.location.replace(getBaseUrl() + 'customer/account/');
    }

    if (!this.state.savingMyRoutine) {
      this.setState({
        savingMyRoutine: true,
      }, function () {
        fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
          },
          body: JSON.stringify({ skus: currentSlugNew }),
          credentials: 'include',
        })
          .then(res => res.json())
          .then(
            (result) => {
              this.setState({ savingMyRoutine: false, });

              if (result && result.success) {
                this.setState({
                  popupSaveMyRoutineSuccessOpen: true,
                });
              } else {
                alert("Error when save routine.");
              }
            },
            (error) => {
              this.setState({ savingMyRoutine: false, });

              console.log(error);
            }
          );
      });
    }
  }

  handleCloseSendMailWarning() {
    this.setState({ popupSendMailWarningOpen: false });
  };

  handleCloseSendMail() {
    this.setState({ popupSendMailOpen: false });
  };

  handleCloseSendMailSuccess() {
    this.setState({ popupSendMailSuccessOpen: false });
  };

  handleCloseSaveMyRoutineSuccess() {
    this.setState({ popupSaveMyRoutineSuccessOpen: false });
  };

  getMainList() {
    const
      productOrderOfUse = this.state.productOrderOfUse,
      result = [];

    Object.keys(productOrderOfUse).forEach(key => {
      const
        periodProducts = [],
        categories = productOrderOfUse[key];

      categories.forEach(category => {
        const
          products = category.products;

        products.forEach(product => {
          if (product.checked) {
            periodProducts.push(product);
          }
        });
      });

      if (periodProducts.length) {
        result.push({
          'title': key,
          'products': periodProducts,
        });
      }
    });

    return result;
  }

  getCurrentSlug() {
    const
      productOrderOfUse = this.state.productOrderOfUse,
      result = [];

    Object.keys(productOrderOfUse).forEach(key => {
      const
        categories = productOrderOfUse[key];

      categories.forEach((category, i) => {
        const
          products = category.products;

        products.forEach(product => {
          if (product.checked) {
            result.push(key + '-' + i + '-' + product.sku);
          }
        });
      });
    });

    return result.join();
  }

  getCurrentSlugNew() {
    const
      productOrderOfUse = this.state.productOrderOfUse,
      result = [];

    Object.keys(productOrderOfUse).forEach(key => {
      const
        categories = productOrderOfUse[key];

      categories.forEach((category, i) => {
        const
          products = category.products;

        products.forEach(product => {
          if (product.checked) {
            if (!result.includes(product.sku)) {
              result.push(product.sku);
            }
          }
        });
      });
    });

    return result;
  }

  changeSwatch(product, csSize) {
    const that = this;
    if (product.type_id === "configurable" && product.children.length) {
      product.children.forEach(function (child) {
        if (child.cs_size === csSize) {
          child.active = true;
        } else {
          child.active = false;
        }
      });
    }
    this.forceUpdate();
    setTimeout(function () {
      that.forceUpdate();
    });
  }

  showMiniCart(miniCart) {
    this.props.showMiniCart(miniCart);
  }

  addToCart(product) {
    if (this.state.adding) {
      return false;
    }

    const
      that = this,
      url = getBaseUrl() + 'checkout/cart/add',
      data = new FormData();

    // Prepare mini cart data
    let
      miniCart = {},
      activeChild = null;
    miniCart.title = product.name;
    miniCart.size = product.size;
    miniCart.formattedPrice = product.formatted_price;
    miniCart.resizedImage = product.resized_image;

    if (product.type_id === "configurable" && product.children.length) {
      activeChild = product.children.filter(child => {
        return child.active === true
      });
      activeChild = activeChild ? activeChild[0] : null;
    }

    if (activeChild) {
      miniCart.size = activeChild.size;
      miniCart.formattedPrice = activeChild.formatted_price;
      miniCart.resizedImage = activeChild.resized_image;
    }

    data.append('product', product.id);
    data.append('form_key', this.props.inputFormKey);
    data.append('qty', 1);
    if (activeChild) {
      data.append('super_attribute[' + getEnv('REACT_APP_ATTRIBUTE_CS_SIZE_ID') + ']', activeChild.cs_size);
    }

    this.setState({ adding: true });
    setTimeout(function () {
      that.forceUpdate();
    });

    fetch(url, {
      method: 'POST',
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
      body: data,
      credentials: 'include',
    }).then(
      (result) => {
        this.props.getCart(function () {
          that.showMiniCart(miniCart);

          that.setState({ adding: false });
          setTimeout(function () {
            that.forceUpdate();
          });
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }

  render() {
    if (window.errorWebsite) {
      return <NoMatch {...this.props} />;
    }

    const
      mainList = this.getMainList();

    return (
      <div className="ga-product-order-of-use ga-container">
        {this.state.readyProductOrderOfUse && this.state.readyContent ? (
          <>
            <div className="img" dangerouslySetInnerHTML={this.state.banner}></div>

            <div className="header" dangerouslySetInnerHTML={this.state.header}></div>

            <Row>
              <Col bsPrefix="col-lg-6 sidebars">
                <div className="guide">
                  <strong>SKINCARE -</strong> Select Your Purchased Products
                </div>

                <div className="grid" dangerouslySetInnerHTML={this.state.grid}></div>

                {!this.isPrint() &&
                  <div className="ga-buttons">
                    <span title="EMAIL YOUR ROUTINE" className="button" onClick={this.handleOpenSendMail}>
                      <span className="label">EMAIL YOUR ROUTINE</span>
                    </span>
                    <span title="SAVE MY ROUTINE" className="button" onClick={this.saveMyRoutine}>
                      {this.state.savingMyRoutine &&
                        <i className="icon fas fa-spinner fa-spin"></i>
                      }

                      <span className="label">SAVE MY ROUTINE</span>
                    </span>
                  </div>
                }
              </Col>

              <Col bsPrefix="col-lg-6 main">
                <div className="guide">
                  <strong>SKINCARE -</strong> Order Of Use
                </div>

                <div className="list">
                  {mainList.length > 0 && (
                    <>
                      {mainList.map(period => {
                        if (period.products.length) {
                          return (
                            <React.Fragment key={period.title}>
                              <div className="each">
                                <strong>{period.title.toUpperCase()} -</strong> Skincare Routine
                              </div>
                              <div className="products">
                                {period.products.map((product, key) => {
                                  let
                                    resizedImage = product.resized_image,
                                    formattedPrice = product.formatted_price;

                                  if (product.type_id === "configurable") {
                                    product.children.forEach(function (child) {
                                      if (child.active) {
                                        resizedImage = child.resized_image;
                                        formattedPrice = child.formatted_price;
                                      }
                                    });
                                  }

                                  return (
                                    <div className="product" key={key}>
                                      <a href={product.product_url} className="img" title={product.name}>
                                        <img className="img-fluid" src={product.resized_image} alt={product.name} />
                                      </a>
                                      <div className="hover">
                                        <a href={product.product_url} className="img" title={product.name}>
                                          <img className="img-fluid" src={resizedImage} alt={product.name} />
                                        </a>
                                        <h3>
                                          <a href={product.product_url}>
                                            {product.name}
                                          </a>
                                        </h3>
                                        <div className="price-box">
                                          <span className="price">{formattedPrice}</span>
                                        </div>
                                        <div className="swatch">
                                          {product.type_id === "simple" ? (
                                            <span className="swatch-option active">{product.size}</span>
                                          ) : ""}
                                          {product.type_id === "configurable" && product.children.length ? (
                                            <>
                                              {product.children.map((child, j) => {
                                                return (
                                                  <span className={child.active ? "swatch-option active" : "swatch-option"} key={j} onClick={this.changeSwatch.bind(this, product, child.cs_size)}>
                                                    {child.size}
                                                  </span>
                                                );
                                              })}
                                            </>
                                          ) : ""}
                                        </div>
                                        <div className="buttons">
                                          {product.is_saleable ? (
                                            <span className="addtocart" onClick={this.addToCart.bind(this, product)}>
                                              {this.state.adding ? (
                                                <i className="fas fa-spinner fa-spin"></i>
                                              ) : "ADD TO BAG"}
                                            </span>
                                          ) : (
                                            <a className="addtocart" href={product.product_url}>
                                              OUT OF STOCK
                                            </a>
                                          )}
                                          <a className="product-item-link" href={product.product_url}>VIEW MORE</a>
                                        </div>
                                      </div>
                                      <div className="inf">
                                        <div className="step">STEP {key + 1}.</div>
                                        <h3 className="title">
                                          <a href={product.product_url} className="link">{product.name}</a><br />
                                          {product.cs_ingredient}
                                        </h3>
                                        <div className="description" dangerouslySetInnerHTML={{ __html: product.short_description }}></div>

                                        <div className="oou_title">DIRECTIONS FOR USE</div>
                                        <div className="description" dangerouslySetInnerHTML={{ __html: product.cs_direction_to_use }}></div>

                                        {product.cs_order_of_use_disclaimer &&
                                          <>
                                            <div className="oou_title">DISCLAIMER</div>
                                            <div className="description" dangerouslySetInnerHTML={{ __html: product.cs_order_of_use_disclaimer }}></div>
                                          </>
                                        }
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </React.Fragment>
                          );
                        } else {
                          return '';
                        }
                      })}
                    </>
                  )}

                  {mainList.length === 0 &&
                    !this.isPrint() && (
                      < div className="empty">Please select your purchased products</div>
                    )}
                </div>

                {!this.isPrint() &&
                  <div className="ga-buttons">
                    <span title="EMAIL YOUR ROUTINE" className="button" onClick={this.handleOpenSendMail}>
                      <span className="label">EMAIL YOUR ROUTINE</span>
                    </span>
                    <span title="SAVE MY ROUTINE" className="button" onClick={this.saveMyRoutine}>
                      {this.state.savingMyRoutine &&
                        <i className="icon fas fa-spinner fa-spin"></i>
                      }

                      <span className="label">SAVE MY ROUTINE</span>
                    </span>
                  </div>
                }
              </Col>
            </Row>
          </>
        ) : (
          <div className="featured loading text-center">
            <i className="fas fa-spinner fa-spin"></i>
          </div>
        )}

        <Modal open={this.state.popupSendMailOpen} onClose={this.handleCloseSendMail} classNames={{ modal: 'ga-popup ga-popup-medium', closeButton: 'ga-popup-close' }} center>
          <h2>Please enter your email</h2>
          <Form
            onSubmit={this.submitSendEmailPopup}
            ref={(node) => { this.refFormSendEmailPopup = node; }}>
            <div className="block-content form-groups row">
              <div className="col-sm-12">
                <div className="field">
                  <label className="label" htmlFor="ga-poou-email"><span>Your Email*</span></label>
                  <div className="control">
                    <Input
                      name="email"
                      id="ga-poou-email"
                      type="text"
                      className="input-text"
                      validations={[required, email]}
                    />
                  </div>
                </div>
                <div className="field">
                  <button type="submit">
                    {this.state.sendingEmail &&
                      <i className="fas fa-spinner fa-spin"></i>
                    }
                    {!this.state.sendingEmail &&
                      <>EMAIL YOUR ROUTINE</>
                    }
                  </button>
                  <Button style={{ display: 'none' }} ref={c => { this.refCheckButtonSendEmailPopup = c }} />
                </div>
              </div>
            </div>
          </Form>
        </Modal>

        <Modal open={this.state.popupSendMailWarningOpen} onClose={this.handleCloseSendMailWarning} classNames={{ modal: 'ga-popup ga-popup-medium', closeButton: 'ga-popup-close' }} center>
          <p>Please select your purchased products.</p>
        </Modal>

        <Modal open={this.state.popupSendMailSuccessOpen} onClose={this.handleCloseSendMailSuccess} classNames={{ modal: 'ga-popup ga-popup-medium', closeButton: 'ga-popup-close' }} center>
          <h2>Success</h2>
          <p>We already sent you an email with all your chosen products.</p>
        </Modal>

        <Modal open={this.state.popupSaveMyRoutineSuccessOpen} onClose={this.handleCloseSaveMyRoutineSuccess} classNames={{ modal: 'ga-popup ga-popup-medium', closeButton: 'ga-popup-close' }} center>
          <h2>Success</h2>
          <p>We already save your routine. You can view your saved routines on my account page.</p>
        </Modal>
      </div >
    )
  }
}

export default ProductOrderOfUse;
