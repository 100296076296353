import { Redirect } from "react-router-dom";
import { Row, Col } from 'react-bootstrap';
import NoMatch from './NoMatch';
import OwlCarousel from 'react-owl-carousel';
import React, { Component } from 'react';
import ReactGA from 'react-ga';
import {
  FacebookShareButton,
  PinterestShareButton,
  TwitterShareButton,
} from 'react-share';

import {
  extractVideoID,
  getApiEndPoint,
  getEnv,
  initStickyFooter,
  isDevelopmentMode,
  setupWebsite,
  getCanonical,
} from './Lib';

class BlogPost extends Component {
  constructor(props) {
    super(props);

    setupWebsite(this.props);

    this.state = {
      ready: false,
      post: null,
      featuredProductsResponsive: {
        0: {
          items: 1,
        },
        576: {
          items: 2,
        },
        768: {
          items: 3,
        },
      },
    }
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);

    if (window.errorWebsite) {
      return false;
    }

    //  init Canonical Tag
    getCanonical(this.props, 5);

    this.getPost();

    initStickyFooter();
  }

  getPost() {
    const { match: { params } } = this.props;
    const url = getApiEndPoint() + 'blog/post/' + params.id;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Authorization": "Bearer " + getEnv('REACT_APP_ACCESS_TOKEN'),
      },
    })
      .then(res => res.json())
      .then(
        (result) => {
          try {
            const jsonResult = JSON.parse(result);
            let post = jsonResult;

            if (post.video) {
              const videoId = extractVideoID(post.video);
              post.videoId = videoId;
            }

            if (post.featured_img) {
              var featured_img_full = getEnv('REACT_APP_MEDIA_URL') + post.featured_img;
              if (!isDevelopmentMode()) {
                featured_img_full = featured_img_full + "?auto=format,compress&fit=fill";
              }
              post.featured_img_full = featured_img_full;
            }

            this.setState({
              ready: true,
              post: post,
            }, function () {
              setTimeout(function () {
                if (window.addthis) {
                  window.addthis.layers.refresh();
                }
              }, 500);
            });
          } catch (e) {
            alert("Error when get blog post.");
          }
        },
        (error) => {
          console.log(error);
        }
      )
  }

  render() {
    if (window.errorWebsite) {
      return <NoMatch {...this.props} />;
    }

    const { match: { params } } = this.props;

    // Redirect to right slug
    if (this.state.post && this.state.post.identifier !== params.slug) {
      return <Redirect to={"/" + window.urlPrefix + "blog/post/" + params.id + "/" + this.state.post.identifier + ".html"} />;
    }

    if (this.state.ready && this.state.post && this.state.post.is_active === "0") {
      return <NoMatch {...this.props} />;
    }

    if (this.state.ready) {
      if (!this.state.post) {
        return <NoMatch {...this.props} />;
      } else if (this.state.post.is_active === "0") {
        return <NoMatch {...this.props} />;
      }
    }

    return (
      <div className="ga-blog-post">
        <div className="ga-toolbar">
          <div className="ga-toolbar-top-page-name">
            BLOG
          </div>
        </div>

        {this.state.ready ? (
          <div className="post">
            <div className="img">
              {(() => {
                const videoId = this.state.post.videoId;

                if (videoId) {
                  if (videoId.platform === 'youtube') {
                    return (
                      <iframe title={this.state.post.title} width="100%" height="600" src={"https://www.youtube.com/embed/" + this.state.post.videoId.id} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    );
                  } else if (videoId.platform === 'vimeo') {
                    return (
                      <iframe title={this.state.post.title} src={"https://player.vimeo.com/video/" + videoId.id} width="100%" height="600" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                    );
                  }
                } else {
                  return (
                    <img
                      className="img-fluid"
                      src={this.state.post.featured_img_full}
                      alt={this.state.post.title} />
                  );
                }
              })()}
            </div>

            <Row bsPrefix="row content">
              <Col lg={6}>
                {this.state.post.first_category ? (
                  <div className="category d-lg-none">
                    {this.state.post.first_category.title}
                  </div>
                ) : ""}
                <h1>{this.state.post.title}</h1>
                <div className="sum d-none d-lg-block" dangerouslySetInnerHTML={{ __html: this.state.post.short_content }}>
                </div>
                <div className="author d-none d-lg-block">
                  {this.state.post.words_by ? (
                    <p>Words by {this.state.post.words_by}</p>
                  ) : ''}
                  {this.state.post.styling_by ? (
                    <p>Styling by {this.state.post.styling_by}</p>
                  ) : ''}
                </div>
                <div className="social d-none d-lg-block">
                  <FacebookShareButton url={window.location.href}>
                    <i className="fab fa-facebook-f"></i>
                  </FacebookShareButton>
                  <TwitterShareButton url={window.location.href}>
                    <i className="fab fa-twitter"></i>
                  </TwitterShareButton>
                  <PinterestShareButton url={window.location.href} media={this.state.post.featured_img_full}>
                    <i className="fab fa-pinterest-p"></i>
                  </PinterestShareButton>
                </div>
              </Col>
              <Col lg={6}>
                <div className="detail" dangerouslySetInnerHTML={{ __html: this.state.post.content }}>
                </div>
                <div className="author d-lg-none">
                  {this.state.post.words_by ? (
                    <p>Words by {this.state.post.words_by}</p>
                  ) : ''}
                  {this.state.post.styling_by ? (
                    <p>Styling by {this.state.post.styling_by}</p>
                  ) : ''}
                </div>
                <div className="social d-lg-none">
                  <FacebookShareButton url={window.location.href}>
                    <i className="fab fa-facebook-f"></i>
                  </FacebookShareButton>
                  <TwitterShareButton url={window.location.href}>
                    <i className="fab fa-twitter"></i>
                  </TwitterShareButton>
                  <PinterestShareButton url={window.location.href} media={this.state.post.featured_img_full}>
                    <i className="fab fa-pinterest-p"></i>
                  </PinterestShareButton>
                </div>
                {this.state.post.related_products && this.state.post.related_products.length ? (
                  <div className="featured_products">
                    <h3>Featured Products</h3>

                    <OwlCarousel
                      className="owl-theme"
                      items={3}
                      nav
                      navText={[
                        '<img class="ga-icon-related-left" src="' + getEnv('REACT_APP_BASE_URL') + 'static/frontend/TPFGroup/GrownAlchemist/en_AU/images/icon/icon-related-left.svg" />',
                        '<img class="ga-icon-related-left" src="' + getEnv('REACT_APP_BASE_URL') + 'static/frontend/TPFGroup/GrownAlchemist/en_AU/images/icon/icon-related-left.svg" />',
                      ]}
                      navElement='div'
                      margin={10}
                      dots={0}
                      responsive={this.state.featuredProductsResponsive}
                    >
                      {this.state.post.related_products.map((product, i) => {
                        return (
                          <div className="item" key={i}>
                            <a className="image" href={product.product_url}>
                              <img title={product.name} alt={product.name} src={product.resized_image} />
                            </a>
                            <h3>
                              <a href={product.product_url}>
                                {product.name}
                              </a>
                            </h3>
                            <span className="price">{product.formatted_price}</span>
                          </div>
                        );
                      })}
                    </OwlCarousel>
                  </div>
                ) : ''}
              </Col>
            </Row>
          </div>
        ) : (
            <div className="loading text-center">
              <i className="fas fa-spinner fa-spin"></i>
            </div>
          )}
      </div>
    );
  }
}

export default BlogPost;
