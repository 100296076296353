// Packages
import React from 'react';

// Image
import makeABookingDesktop from './../images/treatment/make-a-booking-desktop.jpg';
import makeABookingMobile from './../images/treatment/make-a-booking-mobile.jpg';

export default function MakeABookingTemplate() {
  return (
    <div className="ga-treatment">
      <div className="img">
        <img className="d-none d-lg-block img-fluid" src={makeABookingDesktop} alt="MAKE A BOOKING" />
        <img className="d-lg-none img-fluid" src={makeABookingMobile} alt="MAKE A BOOKING" />
      </div>
      <div className="row content">
        <div className="col-lg-6">
          <h1>MAKE A BOOKING</h1>

        </div>
        <div className="col-lg-6">

          <form className="form" data-react="BookingForm">

            <div className="form-group">
              <p>To enquire about treatment availability, complete the form below. A member of our Retail Lab team will be in contact with you within 24 hours.</p>
            </div>

            <div className="form-group">
              <label className="label" htmlFor="ga-booking-firstname"><span>First Name*</span></label>
              <input
                name="firstname"
                id="ga-booking-firstname"
                type="text"
                className="input-text"
              />
            </div>

            <div className="form-group">
              <label className="label" htmlFor="ga-booking-lastname"><span>Last Name*</span></label>
              <input
                name="lastname"
                id="ga-booking-lastname"
                type="text"
                className="input-text"
              />
            </div>

            <div className="form-group">
              <label className="label" htmlFor="ga-booking-telephone"><span>Phone Number*</span></label>
              <input
                name="telephone"
                id="ga-booking-telephone"
                type="text"
                className="input-text"
              />
            </div>

            <div className="form-group">
              <label className="label" htmlFor="ga-booking-email"><span>Email*</span></label>
              <input
                name="email"
                id="ga-booking-email"
                type="text"
                className="input-text"
              />
            </div>

            <div className="form-group">
              <label className="label">
                <span>Your Skin*<br />
                Select the concern(s) you would like help with:</span>
              </label>
              <div className="row">
                <div className="col-sm-6 col-12">
                  <div className="field choice">
                    <label className="label">
                      <span>Fine lines</span>
                      <input
                        type="checkbox"
                        name="skin"
                        className="checkbox"
                        value="Fine lines"
                      />
                    </label>
                  </div>
                  <div className="field choice">
                    <label className="label">
                      <span>Wrinkles</span>
                      <input
                        type="checkbox"
                        name="skin"
                        className="checkbox"
                        value="Wrinkles"
                      />
                    </label>
                  </div>
                  <div className="field choice">
                    <label className="label">
                      <span>Pores</span>
                      <input
                        type="checkbox"
                        name="skin"
                        className="checkbox"
                        value="Pores"
                      />
                    </label>
                  </div>
                  <div className="field choice">
                    <label className="label">
                      <span>Sensitivity</span>
                      <input
                        type="checkbox"
                        name="skin"
                        className="checkbox"
                        value="Sensitivity"
                      />
                    </label>
                  </div>
                  <div className="field choice">
                    <label className="label">
                      <span>Dullness</span>
                      <input
                        type="checkbox"
                        name="skin"
                        className="checkbox"
                        value="Dullness"
                      />
                    </label>
                  </div>
                  <div className="field choice">
                    <label className="label">
                      <span>Blemishes or acne</span>
                      <input
                        type="checkbox"
                        name="skin"
                        className="checkbox"
                        value="Blemishes or acne"
                      />
                    </label>
                  </div>
                </div>
                <div className="col-sm-6 col-12">
                  <div className="field choice">
                    <label className="label">
                      <span>Dark circles</span>
                      <input
                        type="checkbox"
                        name="treatment"
                        className="checkbox"
                        value="Dark circles"
                      />
                    </label>
                  </div>
                  <div className="field choice">
                    <label className="label">
                      <span>Dryness</span>
                      <input
                        type="checkbox"
                        name="treatment"
                        className="checkbox"
                        value="Dryness"
                      />
                    </label>
                  </div>
                  <div className="field choice">
                    <label className="label">
                      <span>Rosacea</span>
                      <input
                        type="checkbox"
                        name="treatment"
                        className="checkbox"
                        value="Rosacea"
                      />
                    </label>
                  </div>
                  <div className="field choice">
                    <label className="label">
                      <span>Sagging or drooping treatment</span>
                      <input
                        type="checkbox"
                        name="treatment"
                        className="checkbox"
                        value="Sagging or drooping treatment"
                      />
                    </label>
                  </div>
                  <div className="field choice">
                    <label className="label">
                      <span>Pigmentation or dark spots</span>
                      <input
                        type="checkbox"
                        name="treatment"
                        className="checkbox"
                        value="Pigmentation or dark spots"
                      />
                    </label>
                  </div>
                  <div className="field choice">
                    <label className="label">
                      <span>Uneven treatment tone</span>
                      <input
                        type="checkbox"
                        name="treatment"
                        className="checkbox"
                        value="Uneven skin tone"
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group">
              <label className="label">
                <span>Treatment*<br />
                Select the Treatment(s) you’re interested in:</span>
              </label>
              <div className="row">
                <div className="col-sm-6 col-12">
                  <div className="field choice">
                    <label className="label">
                      <span>Age-Repair Facial</span>
                      <input
                        type="checkbox"
                        name="treatment"
                        className="checkbox"
                        value="Age-Repair Facial"
                      />
                    </label>
                  </div>
                  <div className="field choice">
                    <label className="label">
                      <span>Hydra-Repair Facial</span>
                      <input
                        type="checkbox"
                        name="treatment"
                        className="checkbox"
                        value="Hydra-Repair Facial"
                      />
                    </label>
                  </div>
                  <div className="field choice">
                    <label className="label">
                      <span>Soothing Facial</span>
                      <input
                        type="checkbox"
                        name="treatment"
                        className="checkbox"
                        value="Soothing Facial"
                      />
                    </label>
                  </div>
                  <div className="field choice">
                    <label className="label">
                      <span>Balancing Facial</span>
                      <input
                        type="checkbox"
                        name="treatment"
                        className="checkbox"
                        value="Balancing Facial"
                      />
                    </label>
                  </div>
                  <div className="field choice">
                    <label className="label">
                      <span>Brightening Facial</span>
                      <input
                        type="checkbox"
                        name="treatment"
                        className="checkbox"
                        value="Brightening Facial"
                      />
                    </label>
                  </div>
                  <div className="field choice">
                    <label className="label">
                      <span>Light Therapy</span>
                      <input
                        type="checkbox"
                        name="treatment"
                        className="checkbox"
                        value="Light Therapy"
                      />
                    </label>
                  </div>
                </div>
                <div className="col-sm-6 col-12">
                  <div className="field choice">
                    <label className="label">
                      <span>Age-Repair Drip</span>
                      <input
                        type="checkbox"
                        name="skin"
                        className="checkbox"
                        value="Age-Repair Drip"
                      />
                    </label>
                  </div>
                  <div className="field choice">
                    <label className="label">
                      <span>Hydra-Repair Drip</span>
                      <input
                        type="checkbox"
                        name="skin"
                        className="checkbox"
                        value="Hydra-Repair Drip"
                      />
                    </label>
                  </div>
                  <div className="field choice">
                    <label className="label">
                      <span>Detox Drip</span>
                      <input
                        type="checkbox"
                        name="skin"
                        className="checkbox"
                        value="Detox Drip"
                      />
                    </label>
                  </div>
                  <div className="field choice">
                    <label className="label">
                      <span>Skin Radiance Drip</span>
                      <input
                        type="checkbox"
                        name="skin"
                        className="checkbox"
                        value="Skin Radiance Drip"
                      />
                    </label>
                  </div>
                  <div className="field choice">
                    <label className="label">
                      <span>Dermal Smoothing Drip</span>
                      <input
                        type="checkbox"
                        name="skin"
                        className="checkbox"
                        value="Dermal Smoothing Drip"
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group">
              <label className="label">
                <span>Treatment Booster*<br />
                Select the Treatment Booster you’re interested in:</span>
              </label>
              <div className="row">
                <div className="col-12">
                  <div className="field choice">
                    <label className="label">
                      <span>Drip Therapy Booster</span>
                      <input
                        type="checkbox"
                        name="booster"
                        className="checkbox"
                        value="Drip Therapy Booster"
                      />
                    </label>
                  </div>
                  <div className="field choice">
                    <label className="label">
                      <span>Eye Contour</span>
                      <input
                        type="checkbox"
                        name="booster"
                        className="checkbox"
                        value="Eye Contour"
                      />
                    </label>
                  </div>
                  <div className="field choice">
                    <label className="label">
                      <span>Top to Toe</span>
                      <input
                        type="checkbox"
                        name="booster"
                        className="checkbox"
                        value="Top to Toe"
                      />
                    </label>
                  </div>
                  <div className="field choice">
                    <label className="label">
                      <span>Head and Scalp Massage</span>
                      <input
                        type="checkbox"
                        name="booster"
                        className="checkbox"
                        value="Head and Scalp Massage"
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group">
              <label className="label" htmlFor="ga-booking-treatment-date"><span>Preferred Treatment Date*</span></label>
              <input
                name="treatment_date"
                id="ga-booking-treatment-date"
                type="date"
                className="input-text"
              />
            </div>

            <div className="form-group">
              <label className="label">
                <span>Preferred Treatment Time*</span>
              </label>
              <div className="row">
                <div className="col-12">
                  <div className="radio">
                    <div className="field choice">
                      <label className="label">
                        <span>Morning</span>
                        <input
                          type="checkbox"
                          name="treatment_time"
                          className="checkbox"
                          value="Morning"
                        />
                      </label>
                    </div>
                    <div className="field choice">
                      <label className="label">
                        <span>Afternoon</span>
                        <input
                          type="checkbox"
                          name="treatment_time"
                          className="checkbox"
                          value="Afternoon"
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group">
              <label className="label">
                <span>Medical Conditions*<br />
                Do you have any medication conditions we should know about?</span>
              </label>
              <div className="row">
                <div className="col-12">
                  <div className="radio">
                    <div className="field choice">
                      <label className="label">
                        <span>Yes</span>
                        <input
                          type="checkbox"
                          name="medical"
                          className="checkbox"
                          value="Yes"
                        />
                      </label>
                    </div>
                    <div className="field choice">
                      <label className="label">
                        <span>No</span>
                        <input
                          type="checkbox"
                          name="medical"
                          className="checkbox"
                          value="No"
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group">
              <label className="label" htmlFor="ga-booking-medical-comment"><span>If so, please advise.</span></label>
              <textarea
                name="medical_comment"
                id="ga-booking-medical-comment"
                className="input-textarea"
              ></textarea>
            </div>

            <div className="row">
              <div className="col-sm-8 col-12">
                <div className="field choice">
                  <label htmlFor="ga-is-agree" className="label">
                    <span>* I acknowledge that I am requesting medical information as well as general information on Grown Alchemist to be sent via email.</span>
                  </label>
                  <input
                    type="checkbox"
                    name="is-agree"
                    id="ga-is-agree"
                    className="checkbox"
                  />
                </div>
              </div>
              <div className="col-sm-4 col-12">
                <div className="field">
                  <button className="button" type="submit">REQUEST</button>
                </div>
              </div>
            </div>

          </form>

        </div>
      </div>
    </div>
  );
}