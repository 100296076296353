// Packages
import React from 'react';

// Image
import dripTherapyDesktop from './../images/treatment/drip-therapy-desktop.jpg';
import dripTherapyMobile from './../images/treatment/drip-therapy-mobile.jpg';

import facialTreatmentsDesktop from './../images/treatment/facial-treatments-desktop.jpg';
import facialTreatmentsMobile from './../images/treatment/facial-treatments-mobile.jpg';

export default function DripTherapyTemplate() {
  return (
    <div className="ga-treatment">
      <div className="img">
        <img className="d-none d-lg-block img-fluid" src={dripTherapyDesktop} alt="DRIP THERAPY" />
        <img className="d-lg-none img-fluid" src={dripTherapyMobile} alt="DRIP THERAPY" />
      </div>
      <div className="row content">
        <div className="col-lg-6">
          <h1>DRIP THERAPY</h1>
          <div className="sum">
            <p>VITAMINS AND MINERALS STRAIGHT TO YOUR CELLS</p>
          </div>
          <div className="copy copy-has-button">
            <p>Drip therapy delivers vitamins and minerals directly to your bloodstream, assisting in optimal nutrient absorption at a cellular level. Our innovative treatments provide immediate beauty benefits, boosting the function of skin cells to achieve your skin goals faster for a more youthful and radiant complexion.</p>

            <p>Unassumingly nestled within the newly completed King&Godfree precinct in the Melbourne inner-city suburb of Carlton, the Grown Alchemist Retail-Lab and treatment rooms are the embodiment of a holistic beauty destination. Drawing inspiration from industrial laboratories, the Retail-Lab is an inviting contrast of heritage architecture, raw and exposed brickwork and modernism.</p>

            <p>On arrival, you will be welcomed by one of our Retail Alchemists into our ‘Cleanroom’– a signature and transformative environment of abundant natural light, glass and stainless steel. Featuring an innovative air-purifying filtration system that combines with Peace Lilly plants to remove toxic gases like benzene, formaldehyde, and carbon monoxide impurities that age the skin from the air; ensuring your skin health is improved from the moment you first arrive.</p>

            <p>Located in the heritage space, our treatment rooms are a warm and enveloping sanctuary that calm your senses, creating an environment to truly focus on achieving your desired skin goals. Offering the latest in-clinic technology and advanced therapy, our treatments offer you a moment of respite, inviting a sense of calm and relaxation on your journey in achieving optimal skin health.</p>

            <p>Our Retail-Lab can be found at 226 Faraday Street, Carlton Victoria. Discover more about our treatments below:</p>

            <p><a className="button" href="/">DOWNLOAD TREATMENT MENU</a><a className="button" href="/">MAKE A BOOKING ENQUIRY</a></p>

            <p><a className="button button-lab" href="/">FACIAL TREATMENTS</a><a className="button button-lab" href="/">DRIP THERAPY</a></p>

            <p><a className="button button-lab" href="/">LIGHT THERAPY</a><a className="button button-lab" href="/">BODY TREATMENTS</a></p>
          </div>
        </div>
        <div className="col-lg-6">

          <div className="detail">
            <div className="list">
              <div className="list-img">
                <img className="d-none d-lg-block img-fluid" src={facialTreatmentsDesktop} alt="FACIAL TREATMENTS" />
                <img className="d-lg-none img-fluid" src={facialTreatmentsMobile} alt="FACIAL TREATMENTS" />
              </div>
              <p><strong>FACIAL TREATMENTS</strong></p>

              <div className="list-img">
                <img className="d-none d-lg-block img-fluid" src={facialTreatmentsDesktop} alt="FACIAL TREATMENTS" />
                <img className="d-lg-none img-fluid" src={facialTreatmentsMobile} alt="FACIAL TREATMENTS" />
              </div>
              <p><strong>DRIP THERAPY</strong></p>
            </div>
          </div>

          <div className="detail">

            <div className="item">
              <p>
                <strong>AGE-REPAIR DRIP - $270</strong><br />
                <strong>Suggested Boosters: Antioxidant Defender, Rejuvenator</strong>
              </p>

              <p>We lose Collagen and Elastin as part of the natural ageing process. These are the fibres which keep your skin firm and plump.  While it is impossible to completely stop the ageing process, you can dramatically slow the effects of ageing and rejuvenate your complexion from the inside out with our Age-Repair Drip. Rich in powerful antioxidants, this drip helps to increase cellular metabolism and stimulates Collagen and Elastin production; the result is a more radiant, youthful looking complexion.</p>

              <p>This drip may be suited if you:<br />
              - Have visible signs of ageing<br />
              - Spend time tanning<br />
              - Enjoy an outdoor lifestyle</p>

              <p>Time: approx. 1 hour</p>
            </div>

            <div className="item">
              <p>
                <strong>HYDRA-REPAIR DRIP - $230</strong><br />
                <strong>Suggested Boosters: Antioxidant Defender, Immunity</strong>
              </p>

              <p>Hydration is vital to achieving optimal skin health. However, travel, exercise, poor diets and prolonged exposure to air-conditioning can leave our skin feeling dehydrated, dull and lifeless.  The Hydra-Repair Drip is the ultimate treatment to instantly boost hydration, improve cellular communication and the health of the skin.</p>

              <p>This drip may be suited if you:<br />
              - Have a dry or combination skin type<br />
              - Are a frequent traveller<br />
              - Suffer from jetlag<br />
              - Play sport<br />
              - Experience varying levels of stress or anxiety</p>

              <p>Time: approx. 1 hour</p>
            </div>

            <div className="item">
              <p>
                <strong>BOOKINGS</strong><br />
              </p>

              <p>Drip Therapy is available at our Retail Lab Carlton location.  To make a booking, please call the Retail Lab on (03) 9113 9558 or use the button below to send us an enquiry.  We will contact you within 24 hours of your enquiry.</p>

              <p><a className="button" href="/">DOWNLOAD TREATMENT MENU</a><a className="button" href="/">MAKE A BOOKING ENQUIRY</a></p>
            </div>

          </div>

        </div>
      </div>
    </div>
  );
}