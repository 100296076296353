import HolidayCollectionTemplate from './../templates/HolidayCollectionTemplate';
import LazyLoad from 'react-lazyload';
import NoMatch from './NoMatch';
import OwlCarousel from 'react-owl-carousel';
import React, { Component } from 'react';
import ReactGA from 'react-ga';

import {
  setupWebsite,
  initStickyFooter,
  getApiEndPoint,
  getBaseUrl,
  getEnv,
  getCanonical,
} from './Lib';

const $ = window.$;

class HolidayCollection extends Component {
  constructor(props) {
    super(props);

    setupWebsite(this.props);

    this.state = {
      useHolidayCollectionTemplate: false,
      enableInitBanner:             false,
      ready:                        false,
      holidayCollectionHtml:        {
        __html: '',
      },
      readyBestSeller:              false,
      ourFavourites:                   [],
      ourFavouritesResponsive:         {
        0: {
          items: 1,
        },
        576: {
          items: 2,
        },
        992: {
          items: 3,
        },
        1336: {
          items: 4,
        },
      },
      ourFavouritesReady:              false,
    }
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);

    if (window.errorWebsite) {
      return false;
    }

    //  init Canonical Tag
    getCanonical(this.props, 1);

    if (this.state.useHolidayCollectionTemplate) {
      this.initBanner();
    } else {
      this.getHolidayCollection();
    }

    this.getOurFavourites();

    initStickyFooter();
  }

  getHolidayCollection() {
    const holidayCollectionIds = {
      au: getEnv('REACT_APP_HOLIDAY_COLLECTION_ID_AU'),
      ca: getEnv('REACT_APP_HOLIDAY_COLLECTION_ID_CA'),
      eu: getEnv('REACT_APP_HOLIDAY_COLLECTION_ID_EU'),
      nz: getEnv('REACT_APP_HOLIDAY_COLLECTION_ID_NZ'),
      uk: getEnv('REACT_APP_HOLIDAY_COLLECTION_ID_UK'),
      us: getEnv('REACT_APP_HOLIDAY_COLLECTION_ID_US'),
    };

    let website = window.website;
    if (website === '') {
      website = 'au';
    }
    if (!holidayCollectionIds[website]) {
      alert("Error when get holiday collection content.");
      return false;
    }

    const url = getApiEndPoint() + 'cmsPage/' + holidayCollectionIds[website];

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Authorization": "Bearer " + getEnv('REACT_APP_ACCESS_TOKEN'),
      },
    })
      .then(res => res.json())
      .then(
        (result) => {
          if (result.content) {
            this.setState({
              ready:        true,
              holidayCollectionHtml: {
                __html: result.content,
              }
            }, function() {
              this.initBanner();
            });
          } else {
            alert("Error when get holidayCollection content.");
          }
        },
        (error) => {
          console.log(error);
        }
      )
  }

  initBanner() {
    if (this.state.enableInitBanner) {
      $('#top-banner').owlCarousel({
        items: 1,
        loop: true,
        margin: 10,
        nav: true,
        navText: [
          '<img class="ga-icon-related-left" src="' + getEnv('REACT_APP_BASE_URL') + 'static/frontend/TPFGroup/GrownAlchemist/en_AU/images/icon/icon-related-left.svg" />',
          '<img class="ga-icon-related-left" src="' + getEnv('REACT_APP_BASE_URL') + 'static/frontend/TPFGroup/GrownAlchemist/en_AU/images/icon/icon-related-left.svg" />',
        ],
        navElement: 'div',
        dots: false,
        onTranslated: function() {
          // Update Magnify when slide changes
        },
        smartSpeed: 800,
      });
    }
  }

  getOurFavourites() {
    const url = getBaseUrl() + 'grownalchemist/api/holidayCollection';

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Authorization": "Bearer " + getEnv('REACT_APP_ACCESS_TOKEN'),
      },
    })
      .then(res => res.json())
      .then(
        (result) => {
          if (result.ourFavourites) {
            let ourFavourites = result.ourFavourites;
            ourFavourites.forEach(function(product) {
              if (product.type_id === 'configurable') {
                if (product.children.length) {
                  product.children[0].active = true;
                }
              }
            });

            this.setState({
              readyBestSeller: true,
              ourFavourites: ourFavourites,
            });
          } else {
            alert("Error when get holiday collection content.");
          }
        },
        (error) => {
          console.log(error);
        }
      )
  }

  addToCart(product) {
    if (this.state.adding) {
      return false;
    }

    const
      that = this,
      url  = getBaseUrl() + 'checkout/cart/add',
      data = new FormData();

    // Prepare mini cart data
    let
      miniCart     = {},
      activeChild = null;
    miniCart.title          = product.name;
    miniCart.size           = product.size;
    miniCart.formattedPrice = product.formatted_price;
    miniCart.resizedImage   = product.resized_image;

    if (product.type_id === "configurable" && product.children.length) {
      activeChild = product.children.filter(child => {
        return child.active === true
      });
      activeChild = activeChild ? activeChild[0] : null;
    }

    if (activeChild) {
      miniCart.size           = activeChild.size;
      miniCart.formattedPrice = activeChild.formatted_price;
      miniCart.resizedImage   = activeChild.resized_image;
    }

    data.append('product', product.id);
    data.append('form_key', this.props.inputFormKey);
    data.append('qty', 1);
    if (activeChild) {
      data.append('super_attribute[' + getEnv('REACT_APP_ATTRIBUTE_CS_SIZE_ID') + ']', activeChild.cs_size);
    }

    this.setState({ adding: true });
    setTimeout(function() {
      that.forceUpdate();
    });

    fetch(url, {
      method: 'POST',
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
      body: data,
      credentials: 'include',
    }).then(
      (result) => {
        this.props.getCart(function() {
          that.showMiniCart(miniCart);

          that.setState({ adding: false });
          setTimeout(function() {
            that.forceUpdate();
          });
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }

  showMiniCart(miniCart) {
    this.props.showMiniCart(miniCart);
  }

  changeSwatch(product, csSize) {
    const that = this;
    if (product.type_id === "configurable" && product.children.length) {
      product.children.forEach(function(child) {
        if (child.cs_size === csSize) {
          child.active = true;
        } else {
          child.active = false;
        }
      });
    }
    this.forceUpdate();
    setTimeout(function() {
      that.forceUpdate();
    });
  }

  render() {
    if (window.errorWebsite) {
      return <NoMatch {...this.props} />;
    }

    return (
      <>
        {(() => {
          if (this.state.useHolidayCollectionTemplate) {
            return (
              <HolidayCollectionTemplate />
            );
          } else if (this.state.ready) {
            return (
              <div className="holiday-collection" dangerouslySetInnerHTML={this.state.holidayCollectionHtml}>
              </div>
            );
          } else {
            return (
              <div className="ga-main loading text-center">
                <i className="fas fa-spinner fa-spin"></i>
              </div>
            );
          }
        })()}

        { this.state.readyBestSeller ? (
          <LazyLoad>
            <div className="ga-best-seller">
              <h2>OUR FAVOURITES</h2>

              <OwlCarousel
                className="owl-theme"
                items={3}
                nav
                navText={[
                  '<img class="ga-icon-related-left" src="' + getEnv('REACT_APP_BASE_URL') + 'static/frontend/TPFGroup/GrownAlchemist/en_AU/images/icon/icon-related-left.svg" />',
                  '<img class="ga-icon-related-left" src="' + getEnv('REACT_APP_BASE_URL') + 'static/frontend/TPFGroup/GrownAlchemist/en_AU/images/icon/icon-related-left.svg" />',
                ]}
                navElement='div'
                margin={9}
                dots={0}
                responsive={ this.state.ourFavouritesResponsive }
                >
                { this.state.ourFavourites.map((product, i) => {
                  if (product.type_id === "configurable" && !product.children.length) {
                    return false;
                  }

                  let
                    resizedImage   = product.resized_image,
                    formattedPrice = product.formatted_price;

                  if (product.type_id === "configurable") {
                    product.children.forEach(function(child) {
                      if (child.active) {
                        resizedImage   = child.resized_image;
                        formattedPrice = child.formatted_price;
                      }
                    });
                  }

                  return (
                    <div className="item" key={i}>
                      <div className="hover">
                        <a className="image" href={ product.product_url }>
                          <img title={ product.name } alt={ product.name } src={ resizedImage } />
                        </a>
                        <h3>
                          <a href={ product.product_url }>
                            { product.name }
                          </a>
                        </h3>
                        { product.cs_ingredient && product.cs_ingredient.trim() ? (
                          <div className="ingredient">
                            { product.cs_ingredient.trim() }
                          </div>
                        ) : "" }
                        <div className="price-box">
                          <span className="price">{ formattedPrice }</span>
                        </div>
                        <div className="swatch">
                          { product.type_id === "simple" ? (
                            <span className="swatch-option active">{ product.size }</span>
                          ) : "" }
                          { product.type_id === "configurable" && product.children.length ? (
                            <>
                              { product.children.map((child, j) => {
                                return (
                                  <span className={ child.active ? "swatch-option active" : "swatch-option" } key={ j } onClick={ this.changeSwatch.bind(this, product, child.cs_size) }>
                                    { child.size }
                                  </span>
                                );
                              })}
                            </>
                          ) : "" }
                        </div>
                        <div className="buttons">
                          { product.is_saleable ? (
                            <span className="addtocart" onClick={ this.addToCart.bind(this, product) }>
                              { this.state.adding ? (
                                <i className="fas fa-spinner fa-spin"></i>
                              ) : "ADD TO BAG" }
                            </span>
                          ) : (
                            <a className="addtocart" href={ product.product_url }>
                              OUT OF STOCK
                            </a>
                          )}
                          <a className="product-item-link" href={ product.product_url }>
                            VIEW MORE
                          </a>
                        </div>
                      </div>
                      <div className="not-hover">
                        <a className="image" href={ product.product_url }>
                          <img title={ product.name } alt={ product.name } src={ product.resized_image } />
                        </a>
                        <h3>
                          <a href={ product.product_url }>
                            { product.name }
                          </a>
                        </h3>
                        <div className="price-box">
                          { product.type_id === "configurable" ? "From " : "" }<span className="price">{ product.formatted_price }</span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </OwlCarousel>
            </div>
          </LazyLoad>
        ) : (
          <div className="ga-main loading text-center">
            <i className="fas fa-spinner fa-spin"></i>
          </div>
        )}
      </>
    );
  }
}

export default HolidayCollection;
