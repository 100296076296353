import React from 'react';

const $ = window.$;

export function setupWebsite(props) {
  const
    websites = [undefined, 'au', 'ca', 'eu', 'nz', 'uk', 'us'],
    { match: { params } } = props;

  if (websites.indexOf(params.website) !== -1) {
    if (params.website === undefined) {
      params.website = 'au';
    }

    window.errorWebsite = false;
    window.website      = params.website;
    window.urlPrefix    = params.website + '/';
  } else {
    window.errorWebsite = true;
  }
}

export function setupWebsiteApp() {
  const websites = [undefined, 'au', 'ca', 'eu', 'nz', 'uk', 'us'];
  let website  = window.location.pathname.substr(1, 2);

  if (websites.indexOf(website) !== -1) {
    if (website === undefined) {
      website = 'au';
    }

    window.website   = website;
    window.urlPrefix = website + '/';
  }
}

export function checkHomepage() {
  const
    websites = ["", 'au', 'ca', 'eu', 'nz', 'uk', 'us'],
    pathname  = window.location.pathname.substr(1).replace(/\/$/, "");

  return websites.indexOf(pathname) !== -1;
}

function stickyFooter() {
  // Reset minHeight
  $('main').css({
    minHeight: 'auto'
  });

  const windowHeight = $(window).height(),
    headerHeight     = $('.ga-header').outerHeight(true),
    mainHeight       = $('main').outerHeight(true),
    footerHeight     = $('.ga-footer').outerHeight(true);

  let mainMinHeight = 'auto';

  if ((headerHeight + mainHeight + footerHeight) < windowHeight) {
    mainMinHeight = windowHeight - headerHeight - footerHeight;
  }

  // Set new minHeight
  $('main').css({
    minHeight: mainMinHeight
  });
}

export function initStickyFooter() {
  stickyFooter();
  window.addEventListener("resize", function() {
    clearTimeout(window.resizeStickyFooter);
    window.resizeStickyFooter = setTimeout(function() {
      stickyFooter();
    }, 1000);
  });
}

export function getApiEndPoint() {
  return getEnv('REACT_APP_BASE_URL') + window.urlPrefix + 'rest/V1/';
}

export function extractVideoID(url) {
  var regExpYoutube = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var matchYoutube = url.match(regExpYoutube);

  var regExpVimeo = /http(s)?:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/;
  var matchVimeo = url.match(regExpVimeo);

  if ( matchYoutube && matchYoutube[7].length === 11 ) {
    return {
      platform: 'youtube',
      id: matchYoutube[7],
    }
  } else if (matchVimeo && matchVimeo[3].length) {
    return {
      platform: 'vimeo',
      id: matchVimeo[3],
    }
  } else {
    alert("Could not extract video ID.");
  }
}

export function isDevelopmentMode() {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    return true;
  } else {
    return false;
  }
}

export function getBaseUrl() {
  return getEnv('REACT_APP_BASE_URL') + window.urlPrefix;
}

export function getEnv(key) {
  if (process.env[key]) {
    return process.env[key].trim();
  } else {
    return undefined;
  }
}

export function checked(value, e) {
  if (!e.checked) {
    return <div className="mage-error" generated="true">This is a required field.</div>
  }
}

/*
**  key param value
**  0: Homepage
**  1: HolidayCollection
**  2: AntiPollutionPrimer
*/
export function getCanonical(props, key=0) {
  const
    websites = ['us', 'uk', 'nz', 'eu', 'ca', 'au' ],
    langs = {'au':'en-AU', 'ca':'en-CA', 'eu':'en-EU', 'nz':'en-NZ', 'uk':'en-GB', 'us':'en-US'},
    { match: { params } } = props,
    alternateLinks = [];

    var canonicalLink = document.createElement('link');
    canonicalLink.rel = 'canonical';

    var currentLink = window.location.href;
    // currentLink = currentLink.replace("/au/", "").replace("/au", "");
    if(window.location.search !== "") currentLink = currentLink.replace(window.location.search, "");
    if(window.location.pathname === "/") currentLink = currentLink.substring(0, currentLink.length-1);

    console.log(currentLink);
    // console.log(window.location);
    
    //  add Canonical link
    canonicalLink.href = currentLink;
    

    websites.forEach(website => {
      var alternateLinkHref = getEnv('REACT_APP_BASE_URL') + website + '/';
      // if (website === 'au' && window.location.pathname.indexOf('/au/') !== -1) alternateLinkHref = getEnv('REACT_APP_BASE_URL') ;
      
      if(key === 1) alternateLinkHref += 'holiday-collection/';
      else if(key === 2) alternateLinkHref += 'bodyguards/';
      else if(key === 3) alternateLinkHref += 'product-order-of-use/';
      else if(key === 4) {
        alternateLinkHref += 'blog/';
        if (params.id) alternateLinkHref += 'category/' + params.id;
      } else if(key === 5) alternateLinkHref += 'blog/post/' + params.id + '/' + params.slug + '.html';
      else if(key === 6) {
        alternateLinkHref += 'page/' + params.slug + '/';
      }

      // if(alternateLinkHref !== currentLink) {
      //   var alternateLink = {'hreflang':langs[website], 'href':alternateLinkHref};
      //   alternateLinks.push(alternateLink);
      // } else if (website !== 'au') {
      //   alternateLinkHref = alternateLinkHref.replace('/'+website+'/', '/');
      //   var alternateLink = {'hreflang':langs[website], 'href':alternateLinkHref};
      //   alternateLinks.push(alternateLink);
      // }
      var alternateLink = {'hreflang':langs[website], 'href':alternateLinkHref};
      alternateLinks.push(alternateLink);
    });

    var canonicalTag = $("link[rel='canonical']").length,
        alternateTag = +$("link[rel='alternate']").length;

    
    alternateLinks.forEach((alternateLink, i) => {
      var alternate = document.createElement('link');
      alternate.rel = 'alternate';
      alternate.hreflang = alternateLink['hreflang'];
      alternate.href = alternateLink['href'];
      if(alternateTag === 0) {
        lp = document.getElementsByTagName('link')[0];
        lp.parentNode.insertBefore(alternate, lp);
      } else {
        $("link[rel='alternate']")[alternateTag-1-i].href = alternateLink['href'];
      }
    });

    if(canonicalTag === 0) {
      var lp = document.getElementsByTagName('link')[0];
      lp.parentNode.insertBefore(canonicalLink, lp);
    } else {
      $("link[rel='canonical']")[0].href = currentLink;
    }
    
}