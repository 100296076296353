import React, { Component } from 'react';

import banner1Desktop from './../images/anti-pollution-primer/banner-1-desktop.jpg';
import banner1Mobile from './../images/anti-pollution-primer/banner-1-mobile.jpg';
import discoverDesktop from './../images/anti-pollution-primer/discover-desktop.jpg';
import discoverMobile from './../images/anti-pollution-primer/discover-mobile.jpg';
import antiPollutionPrimer1Desktop from './../images/anti-pollution-primer/anti-pollution-primer-1-desktop.jpg';
import antiPollutionPrimer1Mobile from './../images/anti-pollution-primer/anti-pollution-primer-1-mobile.jpg';
import antiPollutionPrimer2Desktop from './../images/anti-pollution-primer/anti-pollution-primer-2-desktop.jpg';
import antiPollutionPrimer2Mobile from './../images/anti-pollution-primer/anti-pollution-primer-2-mobile.jpg';
import banner2Desktop from './../images/anti-pollution-primer/banner-2-desktop.jpg';
import banner2Mobile from './../images/anti-pollution-primer/banner-2-mobile.jpg';
import antiPollutionMist1Desktop from './../images/anti-pollution-primer/anti-pollution-mist-1-desktop.jpg';
import antiPollutionMist1Mobile from './../images/anti-pollution-primer/anti-pollution-mist-1-mobile.jpg';
import antiPollutionMist2Desktop from './../images/anti-pollution-primer/anti-pollution-mist-2-desktop.jpg';
import antiPollutionMist2Mobile from './../images/anti-pollution-primer/anti-pollution-mist-2-mobile.jpg';

export default class AntiPollutionPrimerTemplate extends Component {
  render() {
    return (
      <div className="holiday-collection anti-pollution-primer">
        <div className="ga-main ga-main-fluid">
          <div id="top-banner" className="banner">
            <a className="item" href="/">
              <span className="title">
                <span className="h2">
                  INTRODUCING OUR NEW<br />
                  ANTI-POLLUTION BODYGUARDS
                </span>
              </span>
              <img className="d-none d-lg-block img-fluid" src={banner1Desktop} alt="INTRODUCING OUR NEW ANTI-POLLUTION BODYGUARDS" />
              <img className="d-lg-none img-fluid" src={banner1Mobile} alt="INTRODUCING OUR NEW ANTI-POLLUTION BODYGUARDS" />
            </a>
          </div>
        </div>

        <div className="ga-main">
          <div className="discover">
            <div className="row">
              <div className="col-lg-6 image">
                <a href="/">
                  <img className="d-none d-lg-block img-fluid" src={discoverDesktop} alt="BODYGUARDS" />
                  <img className="d-lg-none img-fluid" src={discoverMobile} alt="BODYGUARDS" />
                </a>
              </div>
              <div className="col-lg-6 inf">
                <h2>
                  <a href="/">BODYGUARDS</a>
                </h2>
                <p>Discover your synergistic skin shields. Pre-protection with our Anti-Pollution Primer – used over skincare and under makeup. Paired with our innovative Anti-Pollution Mist, designed for on-the-go pollutant protection and rapid skin rehydration. </p>
                <a className="more" href="/">SHOP ALL</a>
              </div>
            </div>
          </div>
        </div>

        <div className="ga-main">
          <div className="discover2 full-width" style={{"marginTop": "100px"}}>
            <div className="embed-container">
              <iframe title="vimeo-player" src="https://player.vimeo.com/video/382518772" width="640" height="360" frameborder="0" allowfullscreen=""></iframe>
            </div>
            <div className="row">
              <div className="col">
                <h2><a href="/au/holiday-collection"><strong>NEW</strong> ANTI-POLLUTION PRIMER</a></h2>
              </div>
              <div className="col text-right">
                <a className="more" href="/au/new/detox-kit.html">SHOP NOW</a>
              </div>
            </div>
          </div>
        </div>

        <div className="ga-main">
          <div className="discover anti-section text-center">
            <h2><a href="/">ANTI-POLLUTION PRIMER INGREDIENTS INSIGHTS</a></h2>
            <div className="row">
              <div className="col-lg-6 image">
                <a href="/">
                  <img className="d-none d-lg-block img-fluid" src={antiPollutionPrimer1Desktop} alt="ANTI-POLLUTION PRIMER INGREDIENTS INSIGHTS" />
                  <img className="d-lg-none img-fluid" src={antiPollutionPrimer1Mobile} alt="ANTI-POLLUTION PRIMER INGREDIENTS INSIGHTS" />
                </a>
              </div>
              <div className="col-lg-6 image">
                 <a href="/">
                  <img className="d-none d-lg-block img-fluid" src={antiPollutionPrimer2Desktop} alt="ANTI-POLLUTION PRIMER INGREDIENTS INSIGHTS" />
                  <img className="d-lg-none img-fluid" src={antiPollutionPrimer2Mobile} alt="ANTI-POLLUTION PRIMER INGREDIENTS INSIGHTS" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="ga-main">
          <div className="row">
            <div className="col-lg-4">
              <div className="col-landing-3">
                <h4>PREBIOTIC PEPTIDE</h4>
                <p>Provides protection against Pathogens and strengthens skin’s barrier function which protects skin from environmental interference including moisture, extreme temperatures, UV exposure , as well as germs and toxic substances.</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="col-landing-3">
                <h4>INDIAN GINSENG ROOT EXTRACT</h4>
                <p>A natural modulator for biological stressors, Indian Ginseng Extract contains Amino Acids to reinforces skin barrier, protecting it againt articifical, screen-emitted blue light. The herbal extract also contains antisepic, antibacterial, antifungal properties, useful for cleansing the body and skin of toxicity.</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="col-landing-3">
                <h4>GANODERMA EXTRACT</h4>
                <p>Commonly refered to as Resihi Mushroom, this adaptogenic extract is helps to support skin’s biological defence systems including boosting the anti-inflamatory responses, barrier repair and visibly reduce types of skin discolourations including age spots.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="ga-main">
          <div className="text-center mt-60 mb-120">
            <a href="/" className="section-btn ">DISCOVER NOW</a>
          </div>
        </div>

        <div className="ga-main ga-main-fluid">
          <div id="top-banner" className="banner">
            <a className="item" href="/">
              <img className="d-none d-lg-block img-fluid" src={banner2Desktop} alt="BANNER 1" />
              <img className="d-lg-none img-fluid" src={banner2Mobile} alt="BANNER 1" />
            </a>
          </div>
        </div>


        <div className="ga-main">
          <div className="discover anti-section text-center">
            <h2><a href="/">ANTI-POLLUTION MIST INGREDIENTS INSIGHTS</a></h2>
            <div className="row">
              <div className="col-lg-6 image">
                <a href="/">
                  <img className="d-none d-lg-block img-fluid" src={antiPollutionMist1Desktop} alt="ANTI-POLLUTION MIST INGREDIENTS INSIGHTS" />
                  <img className="d-lg-none img-fluid" src={antiPollutionMist1Mobile} alt="ANTI-POLLUTION MIST INGREDIENTS INSIGHTS" />
                </a>
              </div>
              <div className="col-lg-6 image">
                 <a href="/">
                  <img className="d-none d-lg-block img-fluid" src={antiPollutionMist2Desktop} alt="ANTI-POLLUTION MIST INGREDIENTS INSIGHTS" />
                  <img className="d-lg-none img-fluid" src={antiPollutionMist2Mobile} alt="ANTI-POLLUTION MIST INGREDIENTS INSIGHTS" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="ga-main">
          <div className="row">
            <div className="col-lg-4">
              <div className="col-landing-3">
                <h4>BUDDLEJA FLOWER</h4>
                <p>ProvidesAble to adapt to high levels of solar radiation, Buddleja extract is rich in Phenlypropanoids, a powerful antioxidant that stimulates cellular detoxification, reduces skin inflammation  while working to resevvese the effects of photo-aging from UV, Infrared and Blue Light Exposure.</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="col-landing-3">
                <h4>ECHINACEA</h4>
                <p>A plant known for it’s immune-boosting benefits, Echinacea works to eliminate bacteria and other microorganisims that cann cause infection and inflammation to the skin. Rich in tannins, when applied topically Echinacea contains astringent mollecules that reduce the appearance of pore size leading to firmer facial skin, diminishing the appearace of fine lines and wrinkles.</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="col-landing-3">
                <h4>PHYTO-PEPTIDE 1</h4>
                <p>noticeably assists in cellular regeneration and protein synthesis providing the skin the perfect biological building nutrients that are depleted as we age.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="ga-main">
          <div className="text-center mt-60">
            <a href="/" className="section-btn ">DISCOVER NOW</a>
          </div>
        </div>

      </div>
    );
  }
}
