import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import AnimateHeight from 'react-animate-height';
import AntiPollutionPrimer from './components/AntiPollutionPrimer';
import Blog from './components/Blog';
import BlogPost from './components/BlogPost';
import Button from 'react-validation/build/button';
import Cookies from 'universal-cookie';
import Form from 'react-validation/build/form';
import HolidayCollection from './components/HolidayCollection';
import Home from './components/Home';
import iconCart from './images/icon/icon-cart.svg';
import iconMainMenu from './images/icon/icon-main-menu.svg';
import iconMenuBack from './images/icon/icon-menu-back.svg';
import iconSearch from './images/icon/icon-search.svg';
import iconUser from './images/icon/icon-user.svg';
import Input from 'react-validation/build/input';
import Modal from "react-responsive-modal";
import NoMatch from './components/NoMatch';
import Page from './components/Page';
import ProductOrderOfUse from './components/ProductOrderOfUse';
import React, { Component } from 'react';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import Select from 'react-validation/build/select';
import TagManager from 'react-gtm-module';
import Unsubscribe from './components/Unsubscribe';
import validator from 'validator';

import {
  setupWebsiteApp,
  checkHomepage,
  isDevelopmentMode,
  getBaseUrl,
  getEnv,
} from './components/Lib';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './App.css';

const $ = window.$;

const queryString = require('query-string');

const CON_COOKIE_LAW_NAME = 'cookielaw';
const CON_COOKIE_POPUP_CREATE_ACCOUNT = 'popup-create-account';
const CON_COOKIE_FORM_KEY = 'form_key';

const CON_COOKIE_SHIPTO_CCODE = 'shipto_ccode';

const required = (value, e) => {
  if (!value.toString().trim().length) {
    return <div className="mage-error" generated="true">This is a required field.</div>
  }
};

const email = (value) => {
  if (!validator.isEmail(value)) {
    return <div className="mage-error" generated="true">Please enter a valid email address (Ex: johndoe@domain.com).</div>
  }
};

const validatePassword = (value) => {
  var pass = value.toString().trim();
  if (pass.length > 0 && pass.length < 6) {
    return <div className="mage-error" generated="true">Please enter 6 or more characters. Leading and trailing spaces will be ignored.</div>
  }
}

const validatePasswordConfirmation = (value) => {
  if ($('#ga-ca-password').val() !== $('#ga-ca-password-confirmation').val()) {
    return <div className="mage-error" generated="true">Please enter the same value again.</div>
  }
}

const checked = (value, e) => {
  if (!e.checked) {
    return <div className="mage-error" generated="true">This is a required field.</div>
  }
}

// Init GTM
const tagManagerArgs = {
  gtmId: getEnv('REACT_APP_GTM')
};
TagManager.initialize(tagManagerArgs);

// Init GA
ReactGA.initialize(getEnv('REACT_APP_GA'));

// Init Facebook Pixel
const advancedMatching = {};
const options = {
  autoConfig: true,
  debug: isDevelopmentMode(),
};
ReactPixel.init(getEnv('REACT_APP_FABOOK_PIXEL_ID'), advancedMatching, options);

class App extends Component {
  constructor(props) {
    super(props);

    window.website = '';
    window.urlPrefix = '';

    const
      cookies = new Cookies(),
      d = new Date(),
      currentYear = d.getFullYear();

    let
      isWebCrawler = false,
      readyGeoIp = false;

    if (cookies.get('geoipdefaultstore_assigned')) {
      readyGeoIp = true;
    }

    if (navigator.userAgent
      && /Chrome-Lighthouse/.test(navigator.userAgent)) {
      isWebCrawler = true;
      readyGeoIp = true;
    }

    this.state = {
      isCustomerLoggedIn: null,
      currency: '',

      mainMenu: [],
      currentMenu: false,
      currentMenuLevel: [],

      shipTo: [],
      currentShipTo: 'AUSTRALIA',
      currentShipToPopup: 'AUSTRALIA',

      countriesOfCurrentWebsite: [],

      registerPopup: null,

      activeSearch: false,

      openShipTo: false,
      currentShipToIndex: false,

      cartSummaryCount: false,

      popupSubscribeOpen: false,
      popupSubscribeSuccessOpen: false,
      popupSubscribeExistOpen: false,

      inputDobNewsletter: '',
      inputDobNewsletterD: '',
      inputDobNewsletterM: '',
      inputDobNewsletterY: new Date().getFullYear(),

      popupCreateAccountOpen: false,
      inputIsSubscribed: false,

      popupCreateAccountSuccessOpen: false,
      popupCreateAccountExistOpen: false,

      popupMessageOpen: false,
      popupMessageDetail: '',

      popupShippingLocationOpen: false,

      openShipToPopup: false,
      currentShipToIndexPopup: false,
      currentShipToUrlPopup: '',

      inputFormKey: cookies.get(CON_COOKIE_FORM_KEY),

      classHeaderColLeft: 'col-4 col-lg-5 col-xl-5 col-left',
      classHeaderColMiddle: 'col-4 col-lg-2 col-xl-2 col-middle',
      classHeaderColRight: 'col-4 col-lg-5 col-xl-5 col-right',

      accordionFooterStoreLocation: 0,
      accordionFooterInformation: 0,

      showCookieLaw: false,

      isWebCrawler: isWebCrawler,

      readyGeoIp: readyGeoIp,
      common: null,
      readyCommon: false,

      miniCart: {},

      currentYear: currentYear,
    }

    this.handleMenuBack = this.handleMenuBack.bind(this);
    this.handleClickMenu = this.handleClickMenu.bind(this);

    this.toggleSearch = this.toggleSearch.bind(this);
    this.toggleShipTo = this.toggleShipTo.bind(this);
    this.toggleShipToPopup = this.toggleShipToPopup.bind(this);

    this.changeCurrentShipToUrlPopup = this.changeCurrentShipToUrlPopup.bind(this);
    this.submitShipToPopup = this.submitShipToPopup.bind(this);

    this.submitCreateAccountPopup = this.submitCreateAccountPopup.bind(this);

    this.handleClickOutsideMegaMenu = this.handleClickOutsideMegaMenu.bind(this);
    this.handleClickOutsideShipTo = this.handleClickOutsideShipTo.bind(this);
    this.handleClickOutsideShipToPopup = this.handleClickOutsideShipToPopup.bind(this);
    this.handleClickOutsideSearchInput = this.handleClickOutsideSearchInput.bind(this);
    this.handleClickOutsideCookieLaw = this.handleClickOutsideCookieLaw.bind(this);

    this.handleSubscribe = this.handleSubscribe.bind(this);
    this.handleCloseSubscribe = this.handleCloseSubscribe.bind(this);

    this.handleCloseSubscribeExist = this.handleCloseSubscribeExist.bind(this);

    this.handleCloseSubscribeSuccess = this.handleCloseSubscribeSuccess.bind(this);

    this.handleCloseCreateAccount = this.handleCloseCreateAccount.bind(this);

    this.handleCloseCreateAccountExist = this.handleCloseCreateAccountExist.bind(this);

    this.handleCloseCreateAccountSuccess = this.handleCloseCreateAccountSuccess.bind(this);

    this.handleClosePopupMessage = this.handleClosePopupMessage.bind(this);

    this.handleCloseShippingLocation = this.handleCloseShippingLocation.bind(this);

    this.handleChangeDobNewsletterD = this.handleChangeDobNewsletterD.bind(this);
    this.handleChangeDobNewsletterM = this.handleChangeDobNewsletterM.bind(this);

    this.handleChangeIsSubscribed = this.handleChangeIsSubscribed.bind(this);

    this.handleCookieLawClose = this.handleCookieLawClose.bind(this);
  }

  componentDidMount() {
    const
      cookies = new Cookies(),
      parsed = queryString.parse(window.location.search);

    setupWebsiteApp();

    if (parsed.ccode) {
      cookies.set(CON_COOKIE_SHIPTO_CCODE, parsed.ccode, { path: '/' });
    }

    if (!this.state.readyGeoIp) {
      this.getGeoIp();
    } else {
      this.getGeoIPComplete();
    }
  }

  handleChangeDobNewsletterD(event) {
    this.setState({ inputDobNewsletterD: event.target.value }, function () {
      this.replaceDobNewsletter();
    });
  }

  handleChangeDobNewsletterM(event) {
    this.setState({ inputDobNewsletterM: event.target.value }, function () {
      this.replaceDobNewsletter();
    });
  }

  handleChangeIsSubscribed(event) {
    this.setState({ inputIsSubscribed: event.target.checked });
  }

  handleCookieLawClose(event) {
    const cookies = new Cookies();

    cookies.set(CON_COOKIE_LAW_NAME, '1', { path: '/' });

    this.setState({ showCookieLaw: false });
  }

  replaceDobNewsletter() {
    var d = this.state.inputDobNewsletterD;
    var m = this.state.inputDobNewsletterM;
    var y = this.state.inputDobNewsletterY;

    if (d > 0 && m > 0 && y > 0) {
      var dob = m + '/' + d + '/' + y;

      this.setState({ inputDobNewsletter: dob });
    } else {
      this.setState({ inputDobNewsletter: '' });
    }
  }

  handleClickOutsideMegaMenu(event) {
    if (this.refMegaMenu && !this.refMegaMenu.contains(event.target)) {
      if (this.state.currentMenu) {
        this.changeMenu();
      }
    }
  }

  handleClickOutsideShipTo(event) {
    if (this.refShipTo && !this.refShipTo.contains(event.target)
      && this.refOpenShipTo && !this.refOpenShipTo.contains(event.target)) {
      if (this.state.openShipTo) {
        this.setState({
          openShipTo: false,
        });
      }
    }
  }

  handleClickOutsideShipToPopup(event) {
    if (this.refShipToPopup && !this.refShipToPopup.contains(event.target)
      && this.refOpenShipToPopup && !this.refOpenShipToPopup.contains(event.target)) {
      if (this.state.openShipToPopup) {
        this.setState({
          openShipToPopup: false,
        });
      }
    }
  }

  handleClickOutsideSearchInput(event) {
    if (this.searchInput && !this.searchInput.contains(event.target)) {
      const newActiveSearch = false,
        classHeaderCol = this.handleClassHeaderCol(newActiveSearch);

      if (this.state.activeSearch) {
        this.setState({
          activeSearch: newActiveSearch,
          classHeaderColLeft: classHeaderCol.left,
          classHeaderColRight: classHeaderCol.right,
        });
      }
    }
  }

  handleClickOutsideCookieLaw(event) {
    if (this.state.popupCreateAccountOpen) {
      return false;
    }

    if (this.state.popupShippingLocationOpen) {
      return false;
    }

    if (this.refCookieLaw && !this.refCookieLaw.contains(event.target)) {
      this.handleCookieLawClose();
    }
  }

  getGeoIPComplete() {
    const
      parsed = queryString.parse(window.location.search);

    this.getCommon();
    this.getCart();

    if (parsed.subscribe === 'exist') {
      this.setState({ popupSubscribeExistOpen: true });
    }

    if (parsed.subscribe === 'success') {
      this.setState({ popupSubscribeSuccessOpen: true });
    }

    if (parsed.newAccount === 'exist') {
      this.setState({ popupCreateAccountExistOpen: true });
    }

    if (parsed.newAccount === 'success') {
      this.setState({ popupCreateAccountSuccessOpen: true });
    }

    document.addEventListener("mousedown", this.handleClickOutsideMegaMenu);
    document.addEventListener("mousedown", this.handleClickOutsideShipTo);
    document.addEventListener("mousedown", this.handleClickOutsideShipToPopup);
    document.addEventListener("mousedown", this.handleClickOutsideSearchInput);
    document.addEventListener("mousedown", this.handleClickOutsideCookieLaw);

    // Close popup cookie when scrolling down
    /// TODO: Error when using OwlCarousel & scroll page to bottom
    /* $(window).bind("scroll", function() {
      if ($(window).scrollTop() + $(window).height() === $(document).height()) {
        that.handleCookieLawClose();
      }
    }); */
  }

  getGeoIp() {
    const
      url = getEnv('REACT_APP_BASE_URL') + 'grownalchemist/api/geoip';

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
    })
      .then(res => res.json())
      .then(
        (result) => {
          /// Prevent all function for redirecting
          if (
            getEnv('REACT_APP_AUTO_REDIRECT_ENABLED') === 'true'
            && result
            && result.geoIp
            && result.geoIp.redirect === true
            && window.location.pathname.indexOf('/' + window.website + '/product-order-of-use/print/') === -1
          ) {
            window.location.replace(result.geoIp.url);
          } else {
            this.setState({
              readyGeoIp: true,
            });

            this.getGeoIPComplete();
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  addToCartBySKUs(products) {
    const
      url = getBaseUrl() + 'grownalchemist/api/addToCartBySKUs',
      skus = [];

    products.forEach(product => {
      skus.push(product.id);
    });

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        skus: skus,
        form_key: this.state.inputFormKey,
      }),
      credentials: 'include',
    })
      .then(res => res.json())
      .then(
        (result) => {
          if (result.success) {
            if (result.message) {
              this.setState({
                popupMessageOpen: true,
                popupMessageDetail: result.message,
              });
            }
            this.getCart();
          } else if (result.message) {
            alert(result.message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  initRevieve() {
    const
      that = this,
      partnerIds = {
        au: getEnv('REACT_APP_REVIEVE_PARTNER_ID_AU'),
        ca: getEnv('REACT_APP_REVIEVE_PARTNER_ID_CA'),
        eu: getEnv('REACT_APP_REVIEVE_PARTNER_ID_EU'),
        nz: getEnv('REACT_APP_REVIEVE_PARTNER_ID_NZ'),
        uk: getEnv('REACT_APP_REVIEVE_PARTNER_ID_UK'),
        us: getEnv('REACT_APP_REVIEVE_PARTNER_ID_US'),
      },
      env = getEnv('REACT_APP_REVIEVE_ENV');

    let
      website = window.website;
    if (website === '') {
      website = 'au';
    }

    const
      partnerId = partnerIds[website];

    if (partnerId && env) {
      var config = {
        partner_id: partnerId,
        locale: 'en',
        env: env, // for test env. Change to prod for production deployment
        onAddToCart: function (products) {
          // To be filled in by Grown Alchemist
          if (Array.isArray(products)) {
            // Array means we got multiple products (add all to cart)
            // console.log("User clicked add all cart. Adding all products to shopping cart.");
            // console.log(products);
            that.addToCartBySKUs(products);
          } else {
            // Single object means we got one product (add to cart)
            // console.log("User clicked add to cart. Adding the product to shopping cart.");
            // console.log(products);
            that.addToCartBySKUs([products]);
          }
        },
        onClose: function () {
          // User closed modal. forward them to checkout.
          // if (revieveProductsInCart) {
          //   window.location = revieveCheckoutUrl;
          // }
        },
        onCheckout: function () {
          // User clicked the checkout button that is visible on results page
          // after adding one or more products to cart.
          // window.location = revieveCheckoutUrl;
          window.location = getBaseUrl() + 'onestepcheckout/';
        },
        onClickProduct: function (product) {
          // To be filled in by Grown Alchemist.
          console.log("User clicked product.");
          console.log(product);
          var win = window.open(product.url, '_blank');
          win.focus();
        },
      };

      (function () {
        var rv = document.createElement('script');
        rv.src = 'https://d38knilzwtuys1.cloudfront.net/revieve-plugin-v4/revieve-plugin-loader.js';
        rv.charset = 'utf-8';
        rv.type = 'text/javascript';
        rv.async = 'true';
        rv.onload = rv.onreadystatechange = function () {
          var rs = this.readyState;
          if (rs && rs !== 'complete' && rs !== 'loaded') return;
          window.Revieve.Init(config, function () {
            // Comment out the below line if you want to open the modal
            // manually when user clicks a certain button or navigates
            // to certain page.
            // Revieve.API.show();
          });
        };
        var s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(rv, s);
      })();
    }
  }

  initShopBackPixel() {
    (function() {
      var url_string = window.location.href;
      var url = new URL(url_string);
      var source = url.searchParams.get("utm_source");
      var transaction = url.searchParams.get("utm_content");
      if(source === "shopback"){
          console.log("Added ShopBackPixel");
          localStorage.setItem('utm_source', source);
          localStorage.setItem('transaction_id', transaction);
      }

    })();
  
  }
  
  initKlaviyo() {
    (function() {
      const
        klaviyoIds = {
          au: getEnv('REACT_APP_KLAVIYOU_FORM_ID_AU'),
          ca: getEnv('REACT_APP_KLAVIYOU_FORM_ID_CA'),
          eu: getEnv('REACT_APP_KLAVIYOU_FORM_ID_EU'),
          nz: getEnv('REACT_APP_KLAVIYOU_FORM_ID_NZ'),
          uk: getEnv('REACT_APP_KLAVIYOU_FORM_ID_UK'),
          us: getEnv('REACT_APP_KLAVIYOU_FORM_ID_US'),
        };

      let
        website = window.website;
      if (website === '') website = 'au';

      const
        klaviyoId = klaviyoIds[website];

      var kscript = document.createElement('script');
      kscript.src = 'https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=' + klaviyoId;
      kscript.charset = 'utf-8';
      kscript.type = 'text/javascript';
      // var s = document.getElementsByTagName('script')[0];
      // s.parentNode.insertBefore(kscript, s);

      document.body.appendChild(kscript);

    })();
  
  }
  
  getCommon() {
    const
      cookies = new Cookies(),
      url = getBaseUrl() + 'grownalchemist/api/common';

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: 'include',
    })
      .then(res => res.json())
      .then(
        (result) => {
          let
            isCustomerLoggedIn = false;

          if (result && result.isCustomerLoggedIn !== null) {
            isCustomerLoggedIn = result.isCustomerLoggedIn;
            this.setState({
              isCustomerLoggedIn: isCustomerLoggedIn,
            });
          }

          if (result && result.currency) {
            this.setState({
              currency: result.currency,
            });
          }

          if (result && result.menu) {
            let mainMenu = result.menu;

            // Add SEE ALL to main menu
            mainMenu.forEach(menu => {
              if (menu.label === 'SHOP' && menu.sub.length) {
                const
                  subs = menu.sub;

                subs.forEach(sub => {
                  if (sub.sub && sub.sub.length) {
                    sub.sub.push({
                      label: 'SEE ALL',
                      link: sub.link,
                    });
                  }
                });
              }
            });

            // Add Ship To section to main menu
            if (result && result.shipTo && result.shipTo.default && result.shipTo.continents) {
              mainMenu.push({
                label: 'SHIP TO: ' + result.shipTo.default,
                classMore: 'd-lg-none',
                sub: result.shipTo.continents,
              });
            }

            // Add LOG OUT link to main menu
            if (isCustomerLoggedIn) {
              mainMenu.push({
                label: 'LOG OUT',
                link: getBaseUrl() + 'customer/account/logout/',
              });
            }

            // Add header navigation
            if (result.headerNavigation && result.headerNavigation.active) {
              mainMenu.push({
                label: result.headerNavigation.text,
                classMore: 'd-lg-none',
                link: result.headerNavigation.link,
              });
            }

            this.setState({
              mainMenu: mainMenu,
            });
          }

          if (result && result.shipTo && result.shipTo.default && result.shipTo.continents) {
            this.setState({
              currentShipTo: result.shipTo.default,
              currentShipToPopup: result.shipTo.default,
              currentShipToUrlPopup: result.shipTo.defaultUrl,
              shipTo: result.shipTo.continents,
            });
          }

          if (result && result.countriesOfCurrentWebsite) {
            this.setState({
              countriesOfCurrentWebsite: result.countriesOfCurrentWebsite,
            });
          }

          if (result && result.registerPopup) {
            this.setState({
              registerPopup: result.registerPopup,
            });
          }

          if (result && result.isWebCrawler) {
            this.setState({
              isWebCrawler: result.isWebCrawler,
            });
          } else if (window.location.pathname.indexOf('/' + window.website + '/product-order-of-use/print/') === -1) {
            this.initCookieLaw();
            this.initPopupShippingLocation();
            // turn off Create Account Popup
            // this.initPopupCreateAccount();
          }

          if (cookies.get(CON_COOKIE_FORM_KEY) !== result.formKey
            && result && result.formKey) {
            cookies.set(CON_COOKIE_FORM_KEY, result.formKey, { path: '/' });

            this.setState({
              inputFormKey: result.formKey,
            });
          }

          this.setState({
            common: result,
            readyCommon: true,
          });

          this.initRevieve();

          this.initShopBackPixel();

          this.initKlaviyo();
        },
        (error) => {
          console.log(error);
        }
      )
  }

  getCart(callback) {
    const url = getBaseUrl() + 'customer/section/load/?sections=cart';

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: 'include',
    })
      .then(res => res.json())
      .then(
        (result) => {
          if (result && result.cart && result.cart.summary_count !== false) {
            this.setState({
              cartSummaryCount: result.cart.summary_count,
            });
          }

          if (result && result.cart && result.cart.subtotalAmount !== false) {
            var number_words = ['', 'a', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen', 'twenty'];
            var max_tiers = 20;
            var step = 75; // dollars

            var subtotalAmount = result.cart.subtotalAmount;
            var morePrice = 0;
            var numberGet = '';
            var sample = 'mini';

            if (subtotalAmount < step * max_tiers) {
              var tier = parseInt(Math.floor(subtotalAmount / step)) ;
              
              morePrice = step - (subtotalAmount % step);
              numberGet = number_words[tier];
              sample = ((tier > 0) ? 'minis' : 'mini');
            }

            this.setState({
              miniCart: {
                morePrice: morePrice,
                numberGet: numberGet,
                sample: sample,
              },
            });

            if (typeof (callback) == 'function') {
              callback();
            }
          }

          if (typeof (callback) === "function") {
            callback();
          }
        },
        (error) => {
          console.log(error);
        }
      )
  }

  showMiniCart(miniCart) {
    this.setState({
      miniCart: {
        ...this.state.miniCart,
        ...miniCart,
      },
    }, function () {
      $(".ga-minicart-message").fadeIn('fast');

      setTimeout(function () {
        $(".ga-minicart-message").fadeOut('fast');
      }, 6000);
    });
  }

  initCookieLaw() {
    const cookies = new Cookies();

    if (!cookies.get(CON_COOKIE_LAW_NAME)) {
      this.setState({ showCookieLaw: true });
    }
  }

  initPopupCreateAccount() {
    const cookies = new Cookies();

    if (!cookies.get(CON_COOKIE_POPUP_CREATE_ACCOUNT)) {
      cookies.set(CON_COOKIE_POPUP_CREATE_ACCOUNT, '1', { path: '/' });
      this.setState({ popupCreateAccountOpen: true });
    }
  }

  initPopupShippingLocation() {
    const cookies = new Cookies();

    if (!cookies.get(CON_COOKIE_SHIPTO_CCODE)) {
      this.setState({ popupShippingLocationOpen: true });
    }
  }

  handleMenuBack() {
    let menuLevel = this.state.currentMenuLevel;

    if (menuLevel.length) {
      menuLevel.pop();
      this.changeMenu(menuLevel);
    }
  }

  handleClickMenu() {
    this.changeMenu();
  }

  handleClassHeaderCol(newActiveSearch) {
    let classHeaderColLeft, classHeaderColRight;

    if (newActiveSearch) {
      classHeaderColLeft = 'col-4 col-lg-3 col-xl-4 col-left';
      classHeaderColRight = 'col-4 col-lg-7 col-xl-6 col-right';
    } else {
      classHeaderColLeft = 'col-4 col-lg-5 col-xl-5 col-left';
      classHeaderColRight = 'col-4 col-lg-5 col-xl-5 col-right';
    }

    return {
      left: classHeaderColLeft,
      right: classHeaderColRight,
    };
  }

  toggleSearch() {
    const newActiveSearch = !this.state.activeSearch,
      classHeaderCol = this.handleClassHeaderCol(newActiveSearch);

    this.setState({
      activeSearch: newActiveSearch,
      classHeaderColLeft: classHeaderCol.left,
      classHeaderColRight: classHeaderCol.right,
    }, function () {
      if (this.state.activeSearch) {
        this.searchInput.focus();
      }
    });
  }

  toggleShipTo() {
    this.setState({
      openShipTo: !this.state.openShipTo,
    });
  }

  toggleShipToPopup() {
    this.setState({
      openShipToPopup: !this.state.openShipToPopup,
    });
  }

  changeCurrentShipToUrlPopup(e) {
    e.preventDefault();

    this.setState({
      openShipToPopup: false,
      currentShipToIndexPopup: false,
      currentShipToUrlPopup: e.target.href,
      currentShipToPopup: e.target.title,
    });
  }

  submitShipToPopup() {
    window.location.replace(this.state.currentShipToUrlPopup);
  }

  submitCreateAccountPopup(e) {
    e.preventDefault();

    this.refFormCreateAccountPopup.validateAll();

    if (this.refCheckButtonCreateAccountPopup.context._errors.length === 0) {
      document.getElementById('ga-ca').submit();
    }
  }

  changeMenu(menuLevel) {
    if (menuLevel !== undefined) {
      if (typeof menuLevel !== typeof [] && typeof menuLevel === "number") {
        menuLevel = [menuLevel];
      }

      let tmpMenu = [];
      menuLevel.forEach((level, i) => {
        if (i === 0) {
          tmpMenu = this.state.mainMenu;
        } else {
          if (tmpMenu && tmpMenu[level] && tmpMenu[level]['sub']) {
            tmpMenu = tmpMenu[level]['sub'];
          }
        }
      });

      this.setState({
        currentMenu: tmpMenu,
        currentMenuLevel: menuLevel,
      });
    } else {
      this.setState({
        currentMenu: false,
        currentMenuLevel: [],
      });
    }
  }

  changeShipToIndex(i) {
    let currentShipToIndex = false;

    if (i !== this.state.currentShipToIndex) {
      currentShipToIndex = i;
    }

    this.setState({
      currentShipToIndex: currentShipToIndex,
    });
  }

  changeShipToIndexPopup(i) {
    let currentShipToIndexPopup = false;

    if (i !== this.state.currentShipToIndexPopup) {
      currentShipToIndexPopup = i;
    }

    this.setState({
      currentShipToIndexPopup: currentShipToIndexPopup,
    });
  }

  handleSubscribe() {
    this.setState({ popupSubscribeOpen: true });
  };

  handleCloseSubscribe() {
    this.setState({ popupSubscribeOpen: false });
  };

  handleCloseSubscribeSuccess() {
    this.setState({ popupSubscribeSuccessOpen: false });
  };

  handleCloseSubscribeExist() {
    this.setState({ popupSubscribeExistOpen: false });
  };

  handleCloseCreateAccount() {
    this.setState({ popupCreateAccountOpen: false });
  };

  handleCloseCreateAccountSuccess() {
    this.setState({ popupCreateAccountSuccessOpen: false });
  };

  handleClosePopupMessage() {
    this.setState({ popupMessageOpen: false });
  };

  handleCloseCreateAccountExist() {
    this.setState({ popupCreateAccountExistOpen: false });
  };

  handleCloseShippingLocation() {
    this.setState({ popupShippingLocationOpen: false });
  };

  toggleAccordionFooterStoreLocation = () => {
    const { accordionFooterStoreLocation } = this.state;

    this.setState({
      accordionFooterStoreLocation: accordionFooterStoreLocation === 0 ? 'auto' : 0,
    });
  };

  toggleAccordionFooterInformation = () => {
    const { accordionFooterInformation } = this.state;

    this.setState({
      accordionFooterInformation: accordionFooterInformation === 0 ? 'auto' : 0,
    });
  };

  render() {
    if (!this.state.readyGeoIp) {
      return (
        <div className="ga-main loading text-center">
          <i className="fas fa-spinner fa-spin"></i>
        </div>
      );
    }

    return (
      <Router>
        <Switch>
          <Route
            exact
            path="/unsubscribe/:firstname/:email"
            render={
              (props) => <Unsubscribe
                {...props}
                inputFormKey={this.state.inputFormKey}
              />} />

          <Route
            exact
            path="/:website/unsubscribe/:firstname/:email"
            render={
              (props) => <Unsubscribe
                {...props}
                inputFormKey={this.state.inputFormKey}
              />} />

          <Route>
            <div className="ga">
              <header className="ga-header">
                <Container fluid="true">
                  <Row>
                    <Col bsPrefix={this.state.classHeaderColLeft}>
                      {this.state.mainMenu && this.state.mainMenu.length ? (
                        <span className="main-menu" onClick={this.changeMenu.bind(this, 0)}>
                          <img className="icon-main-menu" src={iconMainMenu} alt="Main menu" />
                          <img className="icon-menu-back" src={iconMenuBack} alt="Menu Back" />
                        </span>
                      ) : ''}
                      {this.state.currentMenu && this.state.currentMenu.length ? (
                        <ul className="mega-menu"
                          ref={(node) => { this.refMegaMenu = node; }}>
                          <span className="main-menu-back" onClick={this.handleMenuBack}>
                            <img className="icon-menu-back" src={iconMenuBack} alt="Menu Back" />
                          </span>
                          {this.state.currentMenu.map((menu, i) => {
                            let menuLevel = this.state.currentMenuLevel.slice(0);
                            menuLevel.push(i);

                            return (
                              <li className={menu.classMore ? "mega-menu-item parent " + menu.classMore : "mega-menu-item parent"} key={i}>
                                {(() => {
                                  if (menu.sub && menu.sub.length) {
                                    return (
                                      <span className="menu-link" onClick={this.changeMenu.bind(this, menuLevel)}>
                                        {menu.label}
                                      </span>
                                    );
                                  } else if (menu.url) {
                                    // Ship To
                                    return (
                                      <a className="menu-link" href={menu.url}>
                                        {menu.cn}
                                      </a>
                                    );
                                  } else if (menu.link) {
                                    if (menu.link === getBaseUrl() + 'blog/') {
                                      var linkBlog = "/" + window.urlPrefix + "blog/";
                                      return (
                                        <Link onClick={this.handleClickMenu} className="menu-link" to={linkBlog}>{menu.label}</Link>
                                      );
                                    } else if (menu.link === getBaseUrl() + 'product-order-of-use/') {
                                      var linkProductOrderOfUse = "/" + window.urlPrefix + "product-order-of-use/";
                                      return (
                                        <Link onClick={this.handleClickMenu} className="menu-link" to={linkProductOrderOfUse}>{menu.label}</Link>
                                      );
                                    } else {
                                      return (
                                        <a className="menu-link" href={menu.link}>
                                          {menu.label}
                                        </a>
                                      );
                                    }
                                  } else {
                                    return (
                                      <span className="menu-link">
                                        {menu.label}
                                      </span>
                                    );
                                  }
                                })()}
                              </li>
                            );
                          })}
                        </ul>
                      ) : ''}
                      {this.state.currentMenu && this.state.currentMenu.length ? (
                        <div className="overlay d-lg-none"></div>
                      ) : ''}

                      {this.state.readyCommon && this.state.common.headerNavigation && this.state.common.headerNavigation.active === "1" ? (
                        <a className="header-navigation" href={this.state.common.headerNavigation.link}>
                          {this.state.common.headerNavigation.text}
                        </a>
                      ) : ''}
                    </Col>
                    <Col bsPrefix={this.state.classHeaderColMiddle}>
                      <Link className="logo" to={`/${window.urlPrefix}`} title="Grown Alchemist - Botanical Beauty">
                        <img className="img-fluid" src={getEnv('REACT_APP_MEDIA_URL') + "wysiwyg/cleversoft/logo/logo-ga.svg"} alt="Grown Alchemist - Botanical Beauty" title="Grown Alchemist - Botanical Beauty" />
                      </Link>
                    </Col>
                    <Col bsPrefix={this.state.classHeaderColRight}>
                      <div className="minicart float-right">
                        <a href={getBaseUrl() + "checkout/cart/"}>
                          <img className="icon-cart" src={iconCart} width="14" alt="Cart" />
                          {this.state.cartSummaryCount !== false ? (
                            <span className="counter-number">({this.state.cartSummaryCount})</span>
                          ) : ''}
                        </a>

                        { /* Mini cart */}
                        <div className="ga-minicart-message">
                          <a className="ga-minicart-content" href={getBaseUrl() + "checkout/cart/"}>
                            <div className="ga-head">
                              ITEM ADDED TO SHOPPING BAG
                            </div>
                            <img className="ga-image" alt={this.state.miniCart.title} src={this.state.miniCart.resizedImage} />
                            <div className="ga-inf">
                              <div className="ga-title">
                                {this.state.miniCart.title}
                              </div>
                              <div className="ga-options">
                                {this.state.miniCart.size}
                              </div>
                              <div className="ga-price">
                                <span>{this.state.miniCart.formattedPrice}</span>
                                x1
                              </div>
                            </div>
                            {this.state.miniCart.morePrice ? (
                              <div className="ga-message">
                                You're only {this.state.currency}{this.state.miniCart.morePrice} away from {this.state.miniCart.numberGet} free deluxe {this.state.miniCart.sample}!
                              </div>
                            ) : ""}
                          </a>
                          <a className="ga-minicart-checkout" href={getBaseUrl() + "onestepcheckout/"}>CHECKOUT</a>
                        </div>
                        { /* Mini cart */}

                      </div>
                      <a className="icon-user-link float-right d-none d-lg-block" href={getBaseUrl() + "customer/account/"} title="My Account">
                        <img className="icon-user" src={iconUser} width="17" alt="User" />
                      </a>

                      {/* SEARCH */}
                      <span className="minimal-search float-right" title="Search" onClick={this.toggleSearch}>
                        <img className="icon-search" src={iconSearch} width="16" alt="Search" />
                      </span>
                      <form className="search-form float-right" action={getBaseUrl() + "catalogsearch/result/"} method="get">
                        <input type="hidden" name="product_list_order" value="relevance" />
                        <input type="hidden" name="product_list_dir" value="asc" />
                        <input type="text"
                          ref={(input) => { this.searchInput = input; }}
                          name="q"
                          placeholder="SEARCH HERE"
                          className={this.state.activeSearch ? "input-text active" : "input-text"}
                          maxLength="128" />
                        <button type="submit" title="Search" className="action search">
                        </button>
                      </form>
                      {/* END SEARCH */}

                      {/* SHIPTO */}
                      {this.state.shipTo && this.state.shipTo.length ? (
                        <div className="shipto float-right d-none d-lg-block">
                          <div className={this.state.openShipTo ? "dropdown open" : "dropdown"}>
                            <span className="dropdown-toggle" onClick={this.toggleShipTo}
                              ref={(node) => { this.refOpenShipTo = node; }}>
                              SHIP TO: {this.state.currentShipTo} <b className="sbToggle"></b>
                            </span>
                            {this.state.openShipTo ? (
                              <ul className="dropdown-menu dropdown-menu-right"
                                ref={(node) => { this.refShipTo = node; }}>
                                {this.state.shipTo.map((shipTo, i) => {
                                  return (
                                    <li className={this.state.currentShipToIndex === i ? "dropdown-submenu open" : "dropdown-submenu"} key={i}>
                                      <span className="dropdown-toggle" onClick={this.changeShipToIndex.bind(this, i)}>{shipTo.label}</span>
                                      {shipTo.sub && shipTo.sub.length ? (
                                        <ul className={this.state.currentShipToIndex === i ? "dropdown-menu open" : "dropdown-menu"}>
                                          {shipTo.sub.map((sub, j) => {
                                            return (
                                              <li key={j}>
                                                <a href={sub.url}>-&nbsp;&nbsp;&nbsp;{sub.cn}</a>
                                              </li>
                                            );
                                          })}
                                        </ul>
                                      ) : ''}
                                    </li>
                                  );
                                })}
                              </ul>
                            ) : ''}
                          </div>
                        </div>
                      ) : ''}
                      {/* END SHIPTO */}
                    </Col>
                  </Row>
                </Container>
              </header>

              <Switch>
                <Route
                  exact
                  path="/"
                  render={
                    (props) => <Home
                      {...props}
                      getCart={this.getCart.bind(this)}
                      showMiniCart={this.showMiniCart.bind(this)}
                      inputFormKey={this.state.inputFormKey}
                    />} />
                <Route
                  exact
                  path="/home"
                  render={
                    (props) => <Home
                      {...props}
                      getCart={this.getCart.bind(this)}
                      showMiniCart={this.showMiniCart.bind(this)}
                      inputFormKey={this.state.inputFormKey}
                    />} />
                <Route
                  exact
                  path="/react/index.html"
                  render={
                    (props) => <Home
                      {...props}
                      getCart={this.getCart.bind(this)}
                      showMiniCart={this.showMiniCart.bind(this)}
                      inputFormKey={this.state.inputFormKey}
                    />} />

                <Route
                  exact
                  path="/holiday-collection"
                  key="holiday-collection"
                  render={
                    (props) => <HolidayCollection
                      {...props}
                      getCart={this.getCart.bind(this)}
                      showMiniCart={this.showMiniCart.bind(this)}
                      inputFormKey={this.state.inputFormKey}
                    />
                  }
                />
                <Route
                  exact
                  path="/:website/holiday-collection"
                  key="website-holiday-collection"
                  render={
                    (props) => <HolidayCollection
                      {...props}
                      getCart={this.getCart.bind(this)}
                      showMiniCart={this.showMiniCart.bind(this)}
                      inputFormKey={this.state.inputFormKey}
                    />
                  }
                />

                <Route
                  exact
                  path="/bodyguards"
                  key="bodyguards"
                  render={
                    (props) => <AntiPollutionPrimer
                      {...props}
                      getCart={this.getCart.bind(this)}
                      showMiniCart={this.showMiniCart.bind(this)}
                      inputFormKey={this.state.inputFormKey}
                    />
                  }
                />
                <Route
                  exact
                  path="/:website/bodyguards"
                  key="website-bodyguards"
                  render={
                    (props) => <AntiPollutionPrimer
                      {...props}
                      getCart={this.getCart.bind(this)}
                      showMiniCart={this.showMiniCart.bind(this)}
                      inputFormKey={this.state.inputFormKey}
                    />
                  }
                />

                <Route
                  exact
                  path="/product-order-of-use"
                  key="product-order-of-use"
                  render={
                    (props) => <ProductOrderOfUse
                      {...props}
                      getCart={this.getCart.bind(this)}
                      showMiniCart={this.showMiniCart.bind(this)}
                      readyCommon={this.state.readyCommon}
                      isCustomerLoggedIn={this.state.isCustomerLoggedIn}
                      inputFormKey={this.state.inputFormKey}
                    />
                  }
                />
                <Route
                  exact
                  path="/:website/product-order-of-use"
                  key="website-product-order-of-use"
                  render={
                    (props) => <ProductOrderOfUse
                      {...props}
                      getCart={this.getCart.bind(this)}
                      showMiniCart={this.showMiniCart.bind(this)}
                      readyCommon={this.state.readyCommon}
                      isCustomerLoggedIn={this.state.isCustomerLoggedIn}
                      inputFormKey={this.state.inputFormKey}
                    />
                  }
                />
                <Route
                  exact
                  path="/product-order-of-use/:slugs"
                  key="product-order-of-use"
                  render={
                    (props) => <ProductOrderOfUse
                      {...props}
                      getCart={this.getCart.bind(this)}
                      showMiniCart={this.showMiniCart.bind(this)}
                      readyCommon={this.state.readyCommon}
                      isCustomerLoggedIn={this.state.isCustomerLoggedIn}
                      inputFormKey={this.state.inputFormKey}
                    />
                  }
                />
                <Route
                  exact
                  path="/:website/product-order-of-use/:slugs"
                  key="website-product-order-of-use"
                  render={
                    (props) => <ProductOrderOfUse
                      {...props}
                      getCart={this.getCart.bind(this)}
                      showMiniCart={this.showMiniCart.bind(this)}
                      readyCommon={this.state.readyCommon}
                      isCustomerLoggedIn={this.state.isCustomerLoggedIn}
                      inputFormKey={this.state.inputFormKey}
                    />
                  }
                />
                <Route
                  exact
                  path="/product-order-of-use/print/:slugs"
                  key="product-order-of-use"
                  render={
                    (props) => <ProductOrderOfUse
                      {...props}
                      print={true}
                      getCart={this.getCart.bind(this)}
                      showMiniCart={this.showMiniCart.bind(this)}
                      readyCommon={this.state.readyCommon}
                      isCustomerLoggedIn={this.state.isCustomerLoggedIn}
                      inputFormKey={this.state.inputFormKey}
                    />
                  }
                />
                <Route
                  exact
                  path="/:website/product-order-of-use/print/:slugs"
                  key="website-product-order-of-use"
                  render={
                    (props) => <ProductOrderOfUse
                      {...props}
                      print={true}
                      getCart={this.getCart.bind(this)}
                      showMiniCart={this.showMiniCart.bind(this)}
                      readyCommon={this.state.readyCommon}
                      isCustomerLoggedIn={this.state.isCustomerLoggedIn}
                      inputFormKey={this.state.inputFormKey}
                    />
                  }
                />

                <Route exact path="/blog" key="blog" component={Blog} />
                <Route exact path="/:website/blog" key="website-blog" component={Blog} />

                <Route path="/blog/category/:id" key="blog-category" component={Blog} />
                <Route path="/:website/blog/category/:id" key="website-blog-category" component={Blog} />

                <Route path="/blog/post/:id/:slug.html" component={BlogPost} />
                <Route path="/:website/blog/post/:id/:slug.html" component={BlogPost} />

                <Route
                  exact
                  path="/page/:slug"
                  render={
                    (props) => <Page
                      {...props}
                      inputFormKey={this.state.inputFormKey}
                    />} />

                <Route
                  exact
                  path="/:website/page/:slug"
                  render={
                    (props) => <Page
                      {...props}
                      inputFormKey={this.state.inputFormKey}
                    />} />

                <Route
                  exact
                  path="/:website"
                  render={
                    (props) => <Home
                      {...props}
                      getCart={this.getCart.bind(this)}
                      showMiniCart={this.showMiniCart.bind(this)}
                      inputFormKey={this.state.inputFormKey}
                    />} />

                <Route component={NoMatch} />
              </Switch>

              <footer className="ga-footer">
                <div className="footer-bottom-accordion d-lg-none">
                  <div className="each">
                    <div className="container-fluid">
                      <h4 onClick={this.toggleAccordionFooterStoreLocation}>STORE LOCATOR<span className={this.state.accordionFooterStoreLocation === 0 ? "" : "open"}></span></h4>
                      <AnimateHeight
                        duration={200}
                        height={this.state.accordionFooterStoreLocation}
                      >
                        <div className="footer-link">
                          <form className="store_search" action={getBaseUrl() + "storelocators/"} method="post">
                            <button className="button icon-white" title="Search" type="submit"><span><span>Search</span></span></button>
                            <div className="input-overflow">
                              <input className="form-control" name="bh-sl-findastore" type="text" placeholder="City, Region" />
                            </div>
                          </form>
                        </div>
                      </AnimateHeight>
                    </div>
                  </div>
                  <div className="each">
                    <div className="container-fluid">
                      <h4 onClick={this.toggleAccordionFooterInformation}>INFORMATION<span className={this.state.accordionFooterInformation === 0 ? "" : "open"}></span></h4>
                      <AnimateHeight
                        duration={200}
                        height={this.state.accordionFooterInformation}
                      >
                        <ul className="footer-link">
                          <li><a href={getBaseUrl() + "customer/account/"}>MY ACCOUNT</a></li>
                          <li><a href={getBaseUrl() + "terms-of-use"}>TERMS OF USE</a></li>
                          <li><a href={getBaseUrl() + "customer-service"}>CUSTOMER SERVICE</a></li>
                          <li><a href={getBaseUrl() + "privacy-policy"}>PRIVACY POLICY</a></li>
                          <li>
                            {this.state.isCustomerLoggedIn ? (
                              <a href={getBaseUrl() + "newsletter/manage/"}>SUBSCRIBE FOR NEWS</a>
                            ) : (
                                <span className="subscribepopup" onClick={this.handleSubscribe}>SUBSCRIBE FOR NEWS</span>
                              )}
                          </li>
                          <li><a href={getBaseUrl() + "shipping-returns"}>SHIPPING + RETURNS</a></li>
                          <li><a href="https://knowledgelab.grownalchemist.com/" target="_blank" rel="noopener noreferrer">KNOWLEDGE LAB</a></li>
                        </ul>
                      </AnimateHeight>
                    </div>
                  </div>
                </div>

                <div className="footer__bottom overflow-hidden">
                  <div className="footer__copyright">
                    © GROWN ALCHEMIST <span className="current-year">{this.state.currentYear}</span>
                  </div>
                  <div className="footer__right">
                    <ul>
                      <li className="footer__item">
                        <a href="https://www.facebook.com/grownalchemist/" target="_blank" rel="noopener noreferrer">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li className="footer__item">
                        <a href="https://www.instagram.com/grownalchemist/" target="_blank" rel="noopener noreferrer">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                    </ul>
                    <div className="nav d-none d-lg-block">
                      <ul>
                        <li><a href={getBaseUrl() + "storelocators/"}>STORE LOCATOR</a></li>
                        <li><a href={getBaseUrl() + "customer/account/"}>MY ACCOUNT</a></li>
                        <li><a href={getBaseUrl() + "customer-service"}>CUSTOMER SERVICE</a></li>
                        <li>
                          {this.state.isCustomerLoggedIn ? (
                            <a href={getBaseUrl() + "newsletter/manage/"}>SUBSCRIBE FOR NEWS</a>
                          ) : (
                              <span className="subscribepopup" onClick={this.handleSubscribe}>SUBSCRIBE FOR NEWS</span>
                            )}
                        </li>
                        <li><a href={getBaseUrl() + "terms-of-use"}>TERMS OF USE</a></li>
                        <li><a href={getBaseUrl() + "privacy-policy"}>PRIVACY POLICY</a></li>
                        <li><a href={getBaseUrl() + "shipping-returns"}>SHIPPING + RETURNS</a></li>
                        <li><a href="https://knowledgelab.grownalchemist.com/" target="_blank" rel="noopener noreferrer">KNOWLEDGE LAB</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </footer>

              <Modal open={this.state.popupSubscribeOpen} onClose={this.handleCloseSubscribe} classNames={{ modal: 'ga-popup ga-popup-576 pb-6i', closeButton: 'ga-popup-close' }} center>
                <h2>Subscribe for News</h2>
                <p className="intro">
                  Join the Grown Alchemist community to receive personalized content, news and exclusive offers
                </p>
                <Form action={getBaseUrl() + "newsletter/subscriber/new/"} method="post">
                  <div className="block-content form-groups row">
                    <div className="col-sm-12">
                      <div className="field newsletter">
                        <label className="label" htmlFor="zoo-newsletter"><span>Your Email*</span></label>
                        <div className="control">
                          <Input name="email" id="zoo-newsletter" type="text" className="input-text" validations={[required, email]} />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="field newsletter">
                        <label className="label" htmlFor="zoo-newsletter-firstname"><span>First Name*</span></label>
                        <div className="control">
                          <Input name="firstname" id="zoo-newsletter-firstname" type="text" className="input-text" validations={[required]} />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="field newsletter">
                        <label className="label" htmlFor="zoo-newsletter-lastname"><span>Last Name*</span></label>
                        <div className="control">
                          <Input name="lastname" id="zoo-newsletter-lastname" type="text" className="input-text" validations={[required]} />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="field newsletter">
                        <label className="label" htmlFor="country_id"><span>Country/Region*</span></label>
                        <div className="control">
                          <Select id="country_id" name="country_id" className="required-entry" validations={[required]}>
                            <option value="">Select a country/region</option>
                            {(() => {
                              if (this.state.countriesOfCurrentWebsite) {
                                var options = [];

                                Object.keys(this.state.countriesOfCurrentWebsite).map((key, i) => {
                                  return options.push(
                                    <option key={i}>{this.state.countriesOfCurrentWebsite[key]}</option>
                                  );
                                });

                                return options;
                              }
                            })()}
                          </Select>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="field newsletter">
                        <label className="label" htmlFor="zoo-newsletter-postcode"><span>Zip Code</span></label>
                        <div className="control">
                          <Input name="postcode" type="text" id="zoo-newsletter-postcode" className="input-text" />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12">
                      <div className="field newsletter label-only">
                        <label className="label" htmlFor="ga-newsletter-d"><span>Birthday</span><span className="guide"> - so we can send you a special gift</span></label>
                        <Input type="hidden" name="dob" value={this.state.inputDobNewsletter} />
                        <Input type="hidden" value={this.state.inputDobNewsletterY} />
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="field newsletter">
                        <div className="control">
                          <Select id="ga-newsletter-d" value={this.state.inputDobNewsletterD}
                            onChange={this.handleChangeDobNewsletterD}>
                            <option value="0">Date</option>
                            {(() => {
                              var options = [];
                              for (let i = 1; i < 32; i++) {
                                options.push(<option key={i} value={i}>{i}</option>);
                              }
                              return options;
                            })()}
                          </Select>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="field newsletter">
                        <div className="control">
                          <Select value={this.state.inputDobNewsletterM}
                            onChange={this.handleChangeDobNewsletterM}>
                            <option value="0">Month</option>
                            {(() => {
                              var options = [];
                              for (let i = 1; i < 13; i++) {
                                options.push(<option key={i} value={i}>{i}</option>);
                              }
                              return options;
                            })()}
                          </Select>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="field newsletter">
                        <Button type="submit">SUBSCRIBE</Button>
                      </div>
                    </div>
                  </div>
                </Form>
              </Modal>

              <Modal open={this.state.popupSubscribeExistOpen} onClose={this.handleCloseSubscribeExist} classNames={{ modal: 'ga-popup ga-popup-medium pb-14i', closeButton: 'ga-popup-close' }} center>
                <p>
                  You are already registered as a Grown Alchemist subscriber. To update your newsletter preferences please <a href={getBaseUrl() + 'customer/account/'}>login</a> into your account.
                </p>
              </Modal>

              <Modal open={!!(this.state.readyCommon && this.state.popupShippingLocationOpen && this.state.shipTo && this.state.shipTo.length && checkHomepage())} onClose={this.handleCloseShippingLocation} classNames={{ modal: 'ga-popup ga-popup-medium pb-6i', closeButton: 'ga-popup-close' }} center>
                <h2>Select your shipping location</h2>

                <div className="block-content form-groups row">
                  <div className="col-sm-12">
                    <div className="field">
                      <label className="label"><span>Your current shipping location is:</span></label>
                      {this.state.shipTo && this.state.shipTo.length ? (
                        <div className="control shipping-location">
                          <div className={this.state.openShipToPopup ? "dropdown ga-select-box open" : "dropdown ga-select-box"}>
                            <span className="dropdown-toggle" onClick={this.toggleShipToPopup}
                              ref={(node) => { this.refOpenShipToPopup = node; }}>
                              <span className="popup-shipping-location-title">{this.state.currentShipToPopup}</span> <b className="sbToggle"></b>
                            </span>
                            {this.state.openShipToPopup ? (
                              <ul className="dropdown-menu dropdown-menu-right"
                                ref={(node) => { this.refShipToPopup = node; }}>
                                {this.state.shipTo.map((shipTo, i) => {
                                  return (
                                    <li className={this.state.currentShipToIndexPopup === i ? "dropdown-submenu open" : "dropdown-submenu"} key={i}>
                                      <span className="dropdown-toggle" onClick={this.changeShipToIndexPopup.bind(this, i)}>{shipTo.label}</span>
                                      {shipTo.sub && shipTo.sub.length ? (
                                        <ul className={this.state.currentShipToIndexPopup === i ? "dropdown-menu open" : "dropdown-menu"}>
                                          {shipTo.sub.map((sub, j) => {
                                            return (
                                              <li key={j}>
                                                <a onClick={this.changeCurrentShipToUrlPopup} href={sub.url} title={sub.cn}>-&nbsp;&nbsp;&nbsp;{sub.cn}</a>
                                              </li>
                                            );
                                          })}
                                        </ul>
                                      ) : ''}
                                    </li>
                                  );
                                })}
                              </ul>
                            ) : ''}
                          </div>
                        </div>
                      ) : ''}
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <div className="field">
                      <button type="submit" onClick={this.submitShipToPopup}>OK</button>
                    </div>
                  </div>
                </div>
              </Modal>

              <Modal open={this.state.popupSubscribeSuccessOpen} onClose={this.handleCloseSubscribeSuccess} classNames={{ modal: 'ga-popup ga-popup-medium pb-14i', closeButton: 'ga-popup-close' }} center>
                <h2>Thank you</h2>
                <p>
                  You are now signed up to receive personalized content, news and exclusive offers.
                </p>
              </Modal>

              <Modal open={!!(this.state.readyCommon && this.state.popupCreateAccountOpen)} onClose={this.handleCloseCreateAccount} classNames={{ modal: 'ga-popup ga-popup-576 pb-6i', closeButton: 'ga-popup-close' }} center>
                {this.state.registerPopup && this.state.registerPopup.title && (
                  <h2>{this.state.registerPopup.title}</h2>
                )}

                {this.state.registerPopup && this.state.registerPopup.copy && (
                  <p className="intro">{this.state.registerPopup.copy}</p>
                )}

                <Form
                  action={getBaseUrl() + "customer/account/createpost/"}
                  onSubmit={this.submitCreateAccountPopup}
                  ref={(node) => { this.refFormCreateAccountPopup = node; }}
                  method="post"
                  id="ga-ca">
                  <Input type="hidden" name="form_key" value={this.state.inputFormKey} />
                  <Input type="hidden" name="create_from_popup" value="1" />
                  <Input type="hidden" name="create_address" value="1" />
                  <Input type="hidden" name="default_billing" value="1" />
                  <Input type="hidden" name="default_shipping" value="1" />
                  <Input type="hidden" name="telephone" value="." />
                  <Input type="hidden" name="street[]" value="." />
                  <Input type="hidden" name="city" value="." />

                  <div className="block-content form-groups row">
                    <div className="col-sm-12">
                      <div className="field">
                        <label className="label" htmlFor="ga-ca-email"><span>Your Email*</span></label>
                        <div className="control">
                          <Input name="email" id="ga-ca-email" type="text" className="input-text" validations={[required, email]} />
                        </div>
                      </div>
                      <div className="field choice newsletter">
                        <Input
                          type="checkbox"
                          name="is_subscribed"
                          title="Subscribe for Grown Alchemist News and Promotions"
                          id="ga-is_subscribed"
                          className="checkbox"
                          validations={[checked]}
                          value={this.state.inputIsSubscribed} onChange={this.handleChangeIsSubscribed} />
                        <label htmlFor="ga-is_subscribed" className={this.state.inputIsSubscribed ? "label checked" : "label"}>
                          <span>Subscribe for Grown Alchemist News and Promotions*</span>
                        </label>
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="field">
                        <label className="label" htmlFor="ga-ca-password"><span>Password*</span></label>
                        <div className="control">
                          <Input name="password" id="ga-ca-password" type="password" className="input-text" validations={[required, validatePassword]} />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="field">
                        <label className="label" htmlFor="ga-ca-password-confirmation"><span>Confirm Password*</span></label>
                        <div className="control">
                          <Input name="password_confirmation" id="ga-ca-password-confirmation" type="password" className="input-text" validations={[required, validatePasswordConfirmation]} />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="field">
                        <label className="label" htmlFor="ga-ca-firstname"><span>First Name*</span></label>
                        <div className="control">
                          <Input name="firstname" id="ga-ca-firstname" type="text" className="input-text" validations={[required]} />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="field">
                        <label className="label" htmlFor="ga-ca-lastname"><span>Last Name*</span></label>
                        <div className="control">
                          <Input name="lastname" id="ga-ca-lastname" type="text" className="input-text" validations={[required]} />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="field">
                        <label className="label" htmlFor="ga-ca-country-id"><span>Country/Region*</span></label>
                        <div className="control">
                          <Select id="ga-ca-country-id" name="country_id" className="required-entry" validations={[required]}>
                            <option value="">Select a country/region</option>
                            {(() => {
                              if (this.state.countriesOfCurrentWebsite) {
                                var options = [];

                                Object.keys(this.state.countriesOfCurrentWebsite).map((key, i) => {
                                  return options.push(
                                    <option key={i} value={key}>{this.state.countriesOfCurrentWebsite[key]}</option>
                                  );
                                });

                                return options;
                              }
                            })()}
                          </Select>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="field">
                        <label className="label" htmlFor="ga-ca-postcode"><span>Zip Code</span></label>
                        <div className="control">
                          <Input name="postcode" type="text" id="ga-ca-postcode" className="input-text" />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="field">
                        <button type="submit">CREATE AN ACCOUNT</button>
                        <Button style={{ display: 'none' }} ref={c => { this.refCheckButtonCreateAccountPopup = c }} />
                      </div>
                    </div>
                  </div>
                </Form>
              </Modal>

              <Modal open={this.state.popupCreateAccountExistOpen} onClose={this.handleCloseCreateAccountExist} classNames={{ modal: 'ga-popup ga-popup-576 pb-14i', closeButton: 'ga-popup-close' }} center>
                <p>
                  There is already an account with this email address. If you are sure that it is your email address, <a href={getBaseUrl() + 'customer/account/forgotpassword'}>click here</a> to get your password and access your account.
                </p>
              </Modal>

              <Modal open={this.state.popupCreateAccountSuccessOpen} onClose={this.handleCloseCreateAccountSuccess} classNames={{ modal: 'ga-popup ga-popup-576 pb-14i', closeButton: 'ga-popup-close' }} center>
                <p><strong>You are now signed up as a member of the Grown Alchemist Community.</strong></p>
                <p>To receive 10% off your next purchase, please use code</p>
                <p>WELCOMEGA10</p>
              </Modal>

              <Modal open={this.state.popupMessageOpen} onClose={this.handleClosePopupMessage} classNames={{ modal: 'ga-popup ga-popup-576 pb-14i', closeButton: 'ga-popup-close' }} center styles={{ overlay: { zIndex: 1000000 } }}>
                <p>{this.state.popupMessageDetail}</p>
              </Modal>

              {this.state.showCookieLaw ? (
                <div className="cookielaw"
                  ref={(node) => { this.refCookieLaw = node; }}>
                  <div className="message">
                    To offer you a better online experience, this site uses cookies (technical and profiling cookies from us and third parties). By closing the banner, scrolling down the page, or clicking other items, you agree to the use of cookies. Find out more about our cookie policy and how to refuse cookies by <a href={getBaseUrl() + 'privacy-policy#cookies_policy'}>clicking here</a>.
              </div>
                  <span className="closed" onClick={this.handleCookieLawClose}></span>
                </div>
              ) : ''}
            </div>
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default App;
