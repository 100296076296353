import React, { Component } from 'react';
import ReactGA from 'react-ga';
import NoMatch from './NoMatch';

import './Unsubscribe.css';

import {
  setupWebsite,
  getBaseUrl,
} from './Lib';

class Unsubscribe extends Component {
  constructor(props) {
    super(props);

    setupWebsite(this.props);

    this.state = {
      reasons: [
        'I’m not interested anymore',
        'I received too many of these emails',
        'This content is not relevant to me',
        'This is spam because I already unsubscribed',
      ],
      selectedReason: 'I’m not interested anymore',
      otherReason: '',
      otherReasonError: false,
      firstname: '',
      email: '',
    };

    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.handleReasonChange = this.handleReasonChange.bind(this);
    this.submitSubscriberHistory = this.submitSubscriberHistory.bind(this);
  }

  componentDidMount() {
    const { match: { params } } = this.props;

    ReactGA.pageview(window.location.pathname + window.location.search);

    if (window.errorWebsite) {
      return false;
    }

    this.getMessages();

    this.setState({
      firstname: params.firstname,
      email: params.email,
    });
  }

  getMessages() {
    // TODO
  }

  submitSubscriberHistory() {
    this.setState({
      otherReasonError: false,
    });

    if (this.state.selectedReason === 'Other'
      && !this.state.otherReason) {
      this.setState({
        otherReasonError: true,
      });

      return false;
    }

    if (this.state.submiting) {
      return false;
    }

    if (!this.state.email) {
      alert('Empty email');
      return false;
    }

    if (!this.state.selectedReason) {
      alert('Empty reason');
      return false;
    }

    const
      url = getBaseUrl() + 'grownalchemist/api/submitSubscriberHistory';

    this.setState({ submiting: true });

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: this.state.email,
        reason: this.state.selectedReason,
        detail: this.state.otherReason,
        form_key: this.props.inputFormKey,
      }),
      credentials: 'include',
    })
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({ submiting: false });

          if (result.success) {
            alert('Submit Successful. You will be redirected to the homepage.');
            window.location = getBaseUrl();
          } else if (result.message) {
            alert(result.message);
          }
        },
        (error) => {
          this.setState({ submiting: false });

          alert(error);
        }
      );
  }

  handleOptionChange(changeEvent) {
    this.setState({
      otherReasonError: false,
      selectedReason: changeEvent.target.value,
    });
  }

  handleReasonChange(changeEvent) {
    this.setState({
      otherReasonError: false,
      otherReason: changeEvent.target.value,
    });
  }

  render() {
    if (window.errorWebsite) {
      return <NoMatch {...this.props} />;
    }

    return (
      <div className="unsubscribe">
        <h1>Unsubscribe Successful</h1>

        <p>{this.state.firstname}, we are sorry to see you go. We will no longer send automated emails to your email – {this.state.email}. However, you will still receive order receipts.</p>

        <p>Accidentally unsubscribed? <a className="link" href={getBaseUrl() + 'newsletter/manage/'}>Click here to reconfirm your subscription.</a></p>

        <p><strong>At Grown Alchemist we value customer feedback. We would appreciate if you could please take a moment to let us know why you unsubscribed (Optional):</strong></p>

        {this.state.reasons.map((reason, index) => {
          return (
            <div className="form-check" key={index}>
              <input
                className="form-check-input"
                type="radio"
                name="radioReason"
                id={`radioReason${index}`}
                value={reason}
                checked={this.state.selectedReason === reason}
                onChange={this.handleOptionChange} />
              <label className="form-check-label" htmlFor={`radioReason${index}`}>
                {reason}
              </label>
            </div>
          );
        })}

        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="radioReason"
            id="radioReason-1"
            value="Other"
            checked={this.state.selectedReason === 'Other'}
            onChange={this.handleOptionChange} />
          <label className="form-check-label" htmlFor="radioReason-1">
            Other (fill in reason below)
          </label>
        </div>

        <div className="form-group">
          {this.state.selectedReason === 'Other' && (
            <textarea className="form-control" name="other" rows="3" onChange={this.handleReasonChange}></textarea>
          )}
          {this.state.otherReasonError && (
            <div className="mage-error" generated="true">This is a required field.</div>
          )}
        </div>

        <div className="form-group">
          <button type="submit" className="btn" onClick={this.submitSubscriberHistory}>
            {this.state.submiting ? (
              <i className="fas fa-spinner fa-spin"></i>
            ) : "Submit"}
          </button>
        </div>

        <p>« <a className="link" href={getBaseUrl()}>Return to our website</a></p>
      </div>
    );
  }
}

export default Unsubscribe;